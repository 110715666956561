import { Content, Item, Trigger } from "@radix-ui/react-select"

import isPropValid from "@emotion/is-prop-valid"
import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"

import { optionItemSpacing } from "./constants"
import {
  getSelectContentMaxHeight,
  getSelectContentMaxWidth,
  getSelectContentMinWidth,
  getSelectMinWidth,
} from "./helpers"

export const StyledTrigger = styled(Trigger, {
  shouldForwardProp: prop => isPropValid(prop) || !["size", "error", "triggerMinWidth"].includes(prop),
})`
  min-width: ${({ size, triggerMinWidth }) => getSelectMinWidth({ size, triggerMinWidth })};
  width: 100%;
  max-width: 100%;

  height: 38px;

  display: flex;
  align-items: center;
  gap: ${tokens.spacing[1]};
  justify-content: space-between;

  padding: 0 ${tokens.spacing[3]};
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackground};

  outline-color: ${({ theme }) => theme.colorForegroundFocus};
  border: 1px solid ${({ theme, error }) => (error ? theme.colorBorderError : theme.colorBorderWeak)};

  font-size: ${tokens.typography.fontSize.body};
  color: ${({ theme }) => theme.colorTextStrong};

  [data-ninja-icon] {
    color: ${({ theme }) => theme.colorTextWeakest};
  }

  &:not([data-disabled]) {
    cursor: pointer;
  }

  &[data-disabled] {
    opacity: 1;
    background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
    color: ${({ theme }) => theme.colorTextDisabled};

    > [data-ninja-icon] {
      color: ${({ theme }) => theme.colorTextWeakest};
    }
  }
`

export const StyledBaseContent = styled(Content, {
  shouldForwardProp: prop =>
    isPropValid(prop) ||
    !["listboxMinWidth", "listboxZIndex", "listboxMaxHeight", "optionsLength", "buttonRenderer"].includes(prop),
})`
  overflow-y: auto;
  overflow-x: hidden;

  padding: ${tokens.spacing[1]};
  margin-top: ${tokens.spacing[1]};
  border-radius: ${tokens.borderRadius[1]};
  
  color: ${({ theme }) => theme.colorTextStrong};
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  z-index: ${({ listboxZIndex }) => listboxZIndex || 4000};
  background-color: ${({ theme }) => theme.colorBackground};

  max-height: ${({ listboxMaxHeight, optionsLength }) =>
    getSelectContentMaxHeight({ listboxMaxHeight, optionsLength })};

  ${({ buttonRenderer }) => !buttonRenderer && "width: var(--radix-select-trigger-width);"}
  max-width: ${({ buttonRenderer }) => getSelectContentMaxWidth({ buttonRenderer })};
  min-width: ${({ listboxMinWidth, theme }) => getSelectContentMinWidth({ listboxMinWidth, theme })};

  // To avoid adding a new library (as the docs recommend), we need to add important to activate the scrollbar.
  // Check this: https://www.radix-ui.com/primitives/docs/components/select#with-custom-scrollbar
  [data-radix-select-viewport] {
    overflow: unset !important;
  }
`

export const StyledItem = styled(Item)`
  &:not([data-disabled]) {
    cursor: pointer;
  }

  height: 38px;

  outline: none;
  color: inherit;
  text-align: left;

  display: flex;
  align-items: center;

  // Check: https://github.com/radix-ui/primitives/issues/1658
  user-select: none;

  z-index: 1000;
  padding: ${tokens.spacing[2]};
  border-radius: ${tokens.borderRadius[1]};

  &[data-highlighted] {
    color: inherit;
    background: ${({ theme }) => theme.colorForegroundHover};
  }

  &[data-state="checked"] {
    color: ${({ theme }) => theme.colorTextAction};
    background: ${({ theme }) => theme.colorForegroundSelected};
  }

  &[data-disabled] {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colorTextDisabled};
  }
`

export const StyledOptionTextContainer = styled("div", {
  shouldForwardProp: prop => isPropValid(prop) || !["listboxMinWidth", "buttonRenderer"].includes(prop),
})`
  max-width: ${({ buttonRenderer, listboxMinWidth }) =>
    `calc(${getSelectContentMaxWidth({ buttonRenderer, listboxMinWidth })} - ${optionItemSpacing}px)`};
`
