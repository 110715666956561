import React, { memo, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { isEmpty } from "ramda"
import { isDeleteKey, isEnterKey } from "js/includes/common/utils"
import { Flex, StyledSpan } from "js/includes/components/Styled"

const StyledSearchInput = styled.input`
  background-color: ${({ theme, disabled }) => (disabled ? theme.colorBackgroundInputDisabled : "transparent")};
  color: ${({ theme, disabled }) => (disabled ? theme.colorTextDisabled : theme.colorTextStrong)};
  width: 100%;
  padding: 0;
  display: flex;
  flex: 1;
  height: 100%;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: text;
  }
`

const SingleSelectInput = memo(
  ({
    autoFocus,
    selected,
    value,
    id,
    onChange,
    setFocus,
    focus,
    placeholder,
    disabled,
    openOnFocus,
    setOpen,
    valueRenderer,
    getOptionLabel,
    searchableKey,
    handleDeleteSingleSelection,
    readonly,
    ariaAttributes,
  }) => {
    const inputRef = useRef()

    const selectedValue = getOptionLabel(selected, searchableKey)
    const valueToRender = !value ? selectedValue : ""

    useEffect(() => {
      const handleDelete = e => {
        if (isDeleteKey(e) && !isEmpty(selected) && focus) {
          handleDeleteSingleSelection()
        }
      }

      window.addEventListener("keydown", handleDelete)
      return () => window.removeEventListener("keydown", handleDelete)
    }, [handleDeleteSingleSelection, selected, focus])

    return (
      <Flex
        position="relative"
        alignItems="center"
        flex={1}
        overflow="hidden"
        paddingLeft="10px"
        pointerEvents={readonly ? "none" : "auto"}
      >
        {!value && (
          <StyledSpan
            position="absolute"
            width="100%"
            className="text-ellipsis"
            textAlign="left"
            onClick={() => {
              inputRef.current.focus()
              openOnFocus && !disabled && setOpen(true)
            }}
          >
            {valueRenderer?.(selected) ?? valueToRender}
          </StyledSpan>
        )}

        <StyledSearchInput
          ref={inputRef}
          placeholder={!selectedValue ? placeholder : ""}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onClick={() => openOnFocus && !disabled && setOpen(true)}
          onKeyDown={e => {
            isEnterKey(e) && e.preventDefault()
          }}
          {...{ ...ariaAttributes, value, onChange, autoFocus, disabled, id }}
        />
      </Flex>
    )
  },
)

SingleSelectInput.defaultProps = {
  value: "",
  ariaAttributes: {},
}

export default SingleSelectInput
