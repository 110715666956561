import React, { memo, useState } from "react"
import { isEmpty, clamp, cond, either, isNil, always, equals, identity, T, compose } from "ramda"
import PropTypes from "prop-types"
import { ControlLabel, FormGroup } from "react-bootstrap"
import { Box } from "js/includes/components/Styled"
import { StyledFormControl, StyledRow, StyledCol, StyledForm } from "js/includes/components/Styled/Form"
import { localized, capitalizeFirstLetter, isNumber, isMinusKey } from "js/includes/common/utils"

const normalizeValue = cond([
  [either(isNaN, isNil), always("")],
  [equals(-0), always(0)],
  [T, identity],
])

const NumberOnlyInput = memo(({ value, onChange, name, min, max }) => {
  const [inputValue, setValue] = useState(value)

  const handleChange = e => {
    const {
      target: { value: _value },
    } = e

    const newValue = compose(clamp(min, max), normalizeValue)(parseInt(_value, 10))

    setValue(newValue)
    onChange(newValue)
  }

  function onKeyDown(e) {
    if (isMinusKey(e)) {
      e.preventDefault()
    }
  }

  return (
    <StyledFormControl
      {...{
        name,
        type: "number",
        value: inputValue,
        onChange: handleChange,
        placeholder: "0",
        ...(isNumber(max) && { max }),
        ...(isNumber(min) && { min }),
        ...(isNumber(min) && min === 0 && { onKeyDown }),
      }}
    />
  )
})

const NumberRange = memo(({ from, to, onChange, min, max }) => {
  const [values, setValues] = useState({
    from: from || 0,
    to: to || 0,
  })

  function handleFromChange(newValue) {
    setValues({
      ...values,
      from: newValue,
    })
  }

  function handleToChange(newValue) {
    setValues({
      ...values,
      to: newValue,
    })
  }

  function handleSave(e) {
    e.preventDefault()
    e.stopPropagation()
    onChange(values)
  }

  const isFromValueGreaterThanToValue = values.from > values.to
  const isValueEmpty = isEmpty(values.from) || isEmpty(values.to)

  return (
    <Box>
      <StyledForm paddingLeft={3} paddingRight={3} onSubmit={handleSave}>
        <StyledRow>
          <FormGroup validationState={isFromValueGreaterThanToValue ? "error" : null}>
            <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
              {localized("From")}
            </StyledCol>
            <StyledCol xs={12} flexDirection="column">
              <NumberOnlyInput
                {...{
                  value: values.from,
                  onChange: handleFromChange,
                  name: "from",
                  min,
                  max,
                }}
              />
            </StyledCol>
          </FormGroup>
          <FormGroup validationState={isFromValueGreaterThanToValue ? "error" : null}>
            <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
              {capitalizeFirstLetter(localized("to"))}
            </StyledCol>
            <StyledCol xs={12} flexDirection="column">
              <NumberOnlyInput
                {...{
                  value: values.to,
                  onChange: handleToChange,
                  name: "to",
                  min,
                  max,
                }}
              />
            </StyledCol>
          </FormGroup>
        </StyledRow>
        <StyledRow marginBottom={2} display="flex" alignItems="center" justifyContent="center">
          <StyledCol xs={12} display="inline-block">
            <button
              type="submit"
              className="btn btn-primary full-width"
              disabled={isFromValueGreaterThanToValue || isValueEmpty}
            >
              {localized("Save")}
            </button>
          </StyledCol>
        </StyledRow>
      </StyledForm>
    </Box>
  )
})

NumberRange.defaultProps = {
  from: 0,
  to: 0,
}

NumberRange.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  onChange: PropTypes.func,
}

export default NumberRange
