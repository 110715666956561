import { useCallback } from "react"
import RadioGroupTableFilter from "@ninjaone/webapp/src/js/includes/components/RadioGroupTableFilter/RadioGroupTableFilter"

const RadioGroupFilter = ({
  filter: {
    name,
    value: filterValueList,
    labelToken,
    defaultValue,
    componentProps: { options, isRemovable = !defaultValue, selectedFormatter },
  },
  updateFilter,
  alwaysShowRemove,
  openOnMount,
  onRemove,
  onDropdownClose,
  disabled,
}) => {
  const onChange = useCallback(
    value => {
      updateFilter(name, value)
    },
    [name, updateFilter],
  )

  return (
    <RadioGroupTableFilter
      {...{
        name,
        labelToken,
        options,
        value: filterValueList,
        onChange,
        isRemovable,
        alwaysShowRemove,
        openOnMount,
        onDropdownClose,
        onRemove: () => {
          onRemove?.(name)
          updateFilter(name)
        },
        selectedFormatter,
        disabled,
      }}
    />
  )
}

export default RadioGroupFilter
