import { useEffect, useState } from "react"
import { any, is, defaultTo } from "ramda"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

import { handleChangeModalClass } from "./helpers"
import { useMounted } from "@ninjaone/utils"

export function useSelect({ controlledValue, defaultValue, options, triggerTooltipLabel, onChange, onOpenChange }) {
  const [value, setValue] = useState(defaultValue)

  const mounted = useMounted()
  const { current: isMounted } = mounted

  useEffect(() => {
    if (isMounted && typeof controlledValue !== "undefined") {
      setValue(controlledValue)
    }
  }, [controlledValue, isMounted])

  const valueToUse = defaultTo(undefined, controlledValue || value)

  const [isOpen, setIsOpen] = useState(false)
  const [hideTriggerTooltip, setHideTriggerTooltip] = useState(false)

  const selectedValueOption = options?.find(option => option.value === valueToUse)

  const valueLabel = selectedValueOption?.labelToken
    ? localized(selectedValueOption.labelToken)
    : selectedValueOption?.labelText

  const isMulti = any(is(Array), [valueToUse, defaultValue])

  const handleOnValueChange = newValue => {
    if (triggerTooltipLabel) {
      setHideTriggerTooltip(true)
    }

    setValue(newValue)
    onChange(newValue)
  }

  const handleOnOpenChange = openState => {
    // We just handle the open state manually for the MultiSelect variant.
    if (openState && isMulti) {
      setIsOpen(openState)
    }

    // This is implemented to not remove the class when we are handling the state of the Select component manually.
    if (!openState && isOpen) return

    // Handle the class when it's not a controlled
    handleChangeModalClass(openState)
    onOpenChange?.(openState)
  }

  // Handle the class when it's a controlled element.
  useEffect(() => {
    handleChangeModalClass(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (triggerTooltipLabel && hideTriggerTooltip) {
      // wait until after trigger receives focus to reset the state, if not the tooltip will not be hidden
      setTimeout(() => {
        setHideTriggerTooltip(false)
      }, 0)
    }
  }, [triggerTooltipLabel, hideTriggerTooltip, valueToUse])

  useEffect(() => {
    return () => {
      // make sure class gets removed on unmount
      handleChangeModalClass()
    }
  }, [])

  return {
    isOpen,
    hideTriggerTooltip,
    isMulti,
    valueLabel,
    handleOnValueChange,
    handleOnOpenChange,
    setIsOpen,
    valueToUse,
  }
}
