import { useState } from "react"
import { Root, List, Content } from "@radix-ui/react-tabs"
import PropTypes from "prop-types"
import styled from "@emotion/styled/macro"

import { getTabListStyles } from "../Styled"
import TabTrigger from "./Components/TabTrigger"

const StyledTabList = styled(List)`
  ${({ theme }) => getTabListStyles({ theme })}
`

const StyledTabs = styled(Root)`
  display: grid;
  grid-template-rows: min-content 1fr;
  ${({ height }) => (height ? `height: ${height};` : "")}

  [data-ninja-hover-dropdown-button][aria-expanded="true"] {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }
`

const StyledTabPanels = styled.div`
  display: grid;
  grid-template-rows: 1fr;

  min-height: 0px;
`

const StyledTabPanel = styled(Content)`
  &:not([hidden]) {
    display: grid;
    grid-template-rows: 1fr;

    min-height: 0px;
  }
`

function Tabs({ tabs, defaultIndex = 0, index, hidePanel, onChange, renderOnFocus = false, defaultOption, ...rest }) {
  const [selectedOption, setSelectedOption] = useState(defaultOption)
  const [selectedTabIndex, setSelectedTabIndex] = useState(undefined)

  const handleOnOptionChange = ({ optionIndex, tabIndex, labelText }) => {
    setSelectedOption({ optionIndex, tabIndex, labelText })
    handleOnChangeTab(tabIndex, optionIndex)
  }

  const handleOnChangeTab = (tabIndex, optionIndex) => {
    onChange?.(tabs?.[tabIndex], optionIndex)
    setSelectedTabIndex(tabIndex)
  }

  const value = typeof index === "undefined" ? selectedTabIndex : index

  return (
    <StyledTabs
      {...{
        value,
        defaultValue: defaultIndex,
        onValueChange: handleOnChangeTab,
        activationMode: renderOnFocus ? "automatic" : "manual",
        ...rest,
      }}
    >
      <StyledTabList className="tab-list">
        {tabs.map(({ labelToken, labelText, count, displayError, options, disabled }, currentTabIndex) => (
          <TabTrigger
            {...{
              count,
              options,
              disabled,
              labelText,
              labelToken,
              displayError,
              key: currentTabIndex,
              tabIndex: currentTabIndex,
              onOptionChange: handleOnOptionChange,
              selectedTabIndex: index || selectedTabIndex,
              selectedOptionIndex: selectedOption?.optionIndex,
            }}
          />
        ))}
      </StyledTabList>

      {!hidePanel && (
        <StyledTabPanels>
          {tabs.map(({ labelToken, renderer }, currentTabIndex) => (
            <StyledTabPanel {...{ key: `${labelToken}-${currentTabIndex}`, value: currentTabIndex }}>
              {renderer?.({ selectedOption })}
            </StyledTabPanel>
          ))}
        </StyledTabPanels>
      )}
    </StyledTabs>
  )
}

Tabs.propTypes = {
  /**
   * Array of tab objects
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Indicates if the tab is disabled
       */
      disabled: PropTypes.bool,
      /**
       * Token for label
       */
      labelToken: PropTypes.string,
      /**
       * Label text for the tab
       */
      labelText: PropTypes.string,
      /**
       * Count associated with the tab
       */
      count: PropTypes.number,
      /**
       * Indicates if there's an error to be displayed for the tab
       */
      displayError: PropTypes.bool,
      /**
       * Renderer function for rendering the tab content
       */
      renderer: PropTypes.func,
      /**
       * Options for the tab (e.g., dropdown options)
       */
      options: PropTypes.arrayOf(
        PropTypes.shape({
          /**
           * Action when clicking over the option
           */
          action: PropTypes.func,
          /**
           * Indicates if the option is disabled
           */
          disabled: PropTypes.bool,
          /**
           * Label text for the option
           */
          labelText: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  /**
   * Default index of the selected tab
   */
  defaultIndex: PropTypes.number,
  /**
   * Index of the selected tab for a controlled tab selection
   */
  index: PropTypes.number,
  /**
   * Indicates if the panel (content) should be hidden
   */
  hidePanel: PropTypes.bool,
  /**
   * Function triggered when the selected tab changes
   */
  onChange: PropTypes.func,
  /**
   * Indicates if the content should be rendered on focus
   */
  renderOnFocus: PropTypes.bool,
  /**
   * Indicates the option that should be selected initially.
   */
  defaultOption: PropTypes.shape({
    optionIndex: PropTypes.number.isRequired,
    tabIndex: PropTypes.number.isRequired,
    labelText: PropTypes.string.isRequired,
  }),
}

export default Tabs
