import { default as NinjaOneSwitch } from "../../Switch"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import Text from "./Text"

const FakeSwitch = ({ checked }) => (
  <div className="data-table-fake-switch-container">
    <div className={`data-table-fake-switch ${checked ? "checked" : ""}`} />
    <Text value={checked ? localized("general.enabled") : localized("general.disabled")} />
  </div>
)

const Switch = ({ mouseEnteredRow, ...rest }) => {
  const { checked, onChange } = rest

  return mouseEnteredRow || onChange?.() ? <NinjaOneSwitch {...rest} /> : <FakeSwitch {...{ checked }} />
}

export default Switch
