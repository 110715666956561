const fontFamily = {
  primary: "Inter",
  code: "Fira Mono",
}

const fontSize = {
  headingXl: "3.125rem", // 50px
  headingL: "1.375rem", // 22px
  headingM: "1.125rem", // 18px
  headingS: "1rem", // 16px
  headingXs: "0.875rem", // 14px
  body: "0.875rem", // 14px
  bodyXs: "0.75rem", // 12px
}

const fontWeight = {
  semiBold: 600,
  medium: 500,
  regular: 400,
}

const lineHeight = 1.5

export const typography = {
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
}
