export const spacingBase = 4

export const spacing = {
  1: `${spacingBase}px`,
  2: `${spacingBase * 2}px`,
  3: `${spacingBase * 3}px`,
  4: `${spacingBase * 4}px`,
  5: `${spacingBase * 5}px`,
  6: `${spacingBase * 6}px`,
  7: `${spacingBase * 7}px`,
  8: `${spacingBase * 8}px`,
  9: `${spacingBase * 9}px`,
  10: `${spacingBase * 10}px`,
}
