import { useInViewport } from "@ninjaone/utils"

const LazyRenderer = ({ renderer, mouseEnteredRow, tableContainerWidth }) => {
  const { ref, inViewport } = useInViewport({
    id: "data-table-lazy-renderer",
  })

  return <div ref={ref}>{inViewport && renderer({ mouseEnteredRow, tableContainerWidth })}</div>
}

export default LazyRenderer
