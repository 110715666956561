import { useCallback } from "react"
import DateRangePickerTableFilter from "@ninjaone/webapp/src/js/includes/components/DateRangePickerTableFilter"

const DateRangePickerFilter = ({
  filter: { name, value, labelToken, defaultValue, componentProps: { isRemovable = !defaultValue, disabledDays } = {} },
  updateFilter,
  alwaysShowRemove,
  onRemove,
  openOnMount,
  disabled,
}) => {
  const onChange = useCallback(
    value => {
      updateFilter(name, value)
    },
    [updateFilter, name],
  )

  return (
    <DateRangePickerTableFilter
      {...{
        labelToken,
        value,
        isRemovable,
        alwaysShowRemove,
        onSelect: onChange,
        openOnMount,
        disabledDays,
        onRemove: () => {
          onRemove?.(name)
          updateFilter(name)
        },
        disabled,
      }}
    />
  )
}

export default DateRangePickerFilter
