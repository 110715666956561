import { useEffect, useLayoutEffect, useMemo, useState } from "react"

import { useMounted } from "@ninjaone/utils"

export function useRestoreScroll({ virtualizedListRef, listContainerRef, options, selectedValue, enabled }) {
  const mounted = useMounted()

  const parsedSelectedValue = useMemo(() => (Array.isArray(selectedValue) ? selectedValue : [selectedValue]), [
    selectedValue,
  ])

  const selectedValueIndexes = useMemo(() => {
    const optionIndexMap = new Map(options.map(({ value }, index) => [value, index]))

    return parsedSelectedValue.map(value => optionIndexMap.get(value)).filter(index => index !== undefined)
  }, [options, parsedSelectedValue])

  useLayoutEffect(() => {
    if (virtualizedListRef.current && !mounted.current && enabled) {
      const lastSelectedValueIndex = selectedValueIndexes[selectedValueIndexes.length - 1]

      if (typeof lastSelectedValueIndex === "number" && listContainerRef.current) {
        virtualizedListRef.current.scrollToIndex(lastSelectedValueIndex, { align: "center" })
        const selectedElements = listContainerRef.current.querySelectorAll(
          '[data-ninja-searchable-select-option][aria-selected="true"]',
        )

        if (selectedElements?.length) {
          // This is required to recover the focus after scrolling to the selected element using the virtualized list.
          setTimeout(() => {
            selectedElements[selectedElements.length - 1]?.focus({ preventScroll: true })
          })
        }
      }
    }
  }, [mounted, virtualizedListRef, selectedValueIndexes, enabled, listContainerRef])

  return { selectedValueIndexes }
}

// Options is required to trigger the callback when the list changes.
export function useScrollStatus({ virtualizedListRef, options }) {
  /**
   * Implemented based on the example in the virtua documentation:
   * - https://github.com/inokawa/virtua/blob/main/stories/react/basics/VList.stories.tsx
   */
  const [position, setPosition] = useState(0)
  const [scrolling, setScrolling] = useState(false)

  const [range, setRange] = useState([-1, -1])

  const [isAtTop, setIsAtTop] = useState(false)
  const [isAtBottom, setIsAtBottom] = useState(false)

  useEffect(() => {
    if (!virtualizedListRef.current) return

    if (virtualizedListRef.current.scrollOffset === 0) {
      setIsAtTop(true)
    } else {
      setIsAtTop(false)
    }

    if (
      virtualizedListRef.current.scrollOffset -
        virtualizedListRef.current.scrollSize +
        virtualizedListRef.current.viewportSize >=
      -1.5
    ) {
      setIsAtBottom(true)
    } else {
      setIsAtBottom(false)
    }
  }, [position, virtualizedListRef, options])

  function handleOnScroll(offset) {
    setPosition(offset)
    setScrolling(true)
  }

  function handleOnScrollEnd() {
    setScrolling(false)
  }

  function handleOnRangeChange(start, end) {
    setRange([start, end])
  }

  return {
    isAtTop,
    isAtBottom,
    scrolling,
    range,
    scrollTop: position,
    handleOnScroll,
    handleOnScrollEnd,
    handleOnRangeChange,
  }
}
