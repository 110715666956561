import React, { Component, Fragment } from "react"
import { localized } from "js/includes/common/utils"
import DayPicker from "react-day-picker"
import { DateUtils } from "react-day-picker"
import "react-day-picker/lib/style.css"

export default class DateRangePicker extends Component {
  static defaultProps = {
    enableReset: true,
  }

  constructor(props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this)
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
    this.state = this.getInitialState()
  }
  componentDidMount() {
    if (this.props.selectedDays && this.props.selectedDays.from && this.props.selectedDays.to) {
      this.setState({
        from: new Date(this.props.selectedDays.from),
        to: new Date(this.props.selectedDays.to),
      })
    }
  }
  getInitialState() {
    return {
      from: null,
      to: null,
      enteredTo: null,
    }
  }
  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
    const isRangeSelected = from && to
    return !from || isBeforeFirstDay || isRangeSelected
  }
  handleDayClick = async day => {
    const { from, to } = this.state
    if (from && to && day >= from && day <= to) {
      this.handleResetClick()
      return
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      await this.setState({
        from: day,
        to: null,
        enteredTo: null,
      })
    } else {
      await this.setState({
        to: day,
        enteredTo: day,
      })
    }

    // Lift state to parent
    if (this.props.dateRangeSelector) {
      this.props.dateRangeSelector(this.state)
    }
  }
  handleDayMouseEnter = day => {
    const { from, to } = this.state
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      })
    }
  }
  handleResetClick = () => {
    const { dateRangeSelector } = this.props
    if (this.state.from || this.state.to) {
      this.setState(this.getInitialState(), () => {
        dateRangeSelector && dateRangeSelector(this.state)
      })
    }
  }
  render() {
    const toMonth = this.props.toMonth || new Date()
    const modifiers = { start: this.state.from, end: this.state.enteredTo || this.state.to }
    const disabledDays = this.props.disabledDays || { after: toMonth }
    const selectedDays = [this.state.from, { from: this.state.from, to: this.state.enteredTo || this.state.to }]

    return (
      <Fragment>
        {this.props.enableReset && (
          <div className="text-right">
            <button type="button" className="btn-link m-r-sm" onClick={this.handleResetClick}>
              {localized("Reset")}
            </button>
          </div>
        )}
        <DayPicker
          className={`DateRangePicker ${this.props.className}`}
          numberOfMonths={2}
          toMonth={toMonth}
          selectedDays={selectedDays}
          disabledDays={disabledDays}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
          onDayMouseEnter={this.handleDayMouseEnter}
          fromMonth={this.props.fromMonth}
        />
      </Fragment>
    )
  }
}
