import { PureComponent } from "react"
import PropTypes from "prop-types"
import FocusTrap from "../FocusTrap"

export default function withFocusTrap(WrappedComponent) {
  return class extends PureComponent {
    state = {
      focusTrapEnabled: false,
    }

    componentDidMount() {
      setTimeout(() => {
        // FocusTrap is disabled on tests to avoid getting timeout errors due to react-focus performance issues with jsdom
        const shouldEnableFocusTrap =
          process.env.NODE_ENV === "test" ? this.props.enableFocusTrapInTests : !this.props.focusTrapDisabled
        if (shouldEnableFocusTrap) {
          this.setState({ focusTrapEnabled: true })
        }
      }, 0)
    }

    render() {
      return (
        <FocusTrap returnFocus={this.props.returnFocus} disabled={!this.state.focusTrapEnabled}>
          <WrappedComponent {...this.props} />
        </FocusTrap>
      )
    }
  }
}

withFocusTrap.propTypes = {
  focusTrapDisabled: PropTypes.bool,
  returnFocus: PropTypes.func,
}
