import { Icon } from "@radix-ui/react-select"
import { SelectIcon } from "@ninjaone/icons"
import Text from "../../Text"

export function SelectIconComponent({ indicatorIconRenderer }) {
  return (
    <Icon data-ninja-icon="">
      <Text size="sm">{indicatorIconRenderer?.() ?? <SelectIcon />}</Text>
    </Icon>
  )
}
