import { useRef } from "react"
import styled from "@emotion/styled"
import { Input } from "@ninjaone/components"
import { useMountedState } from "js/includes/common/hooks"

const StyledInput = styled(Input)`
  height: ${({ height }) => height ?? "38px"};
  min-width: auto;
  width: ${({ width }) => width ?? "125px"};
`

const getValidationResult = (validators = [], inputValue) => {
  let result = {
    success: true,
  }

  validators.some(validator => {
    const validationResult = validator(inputValue)
    if (!validationResult.success) {
      result = validationResult
    }
    return !validationResult.success
  })

  return result
}

const DebouncedInput = ({
  debounceTime = 250,
  type = "number",
  defaultValue = "",
  value = defaultValue,
  onChange,
  validators,
  ...restInputProps
}) => {
  const timerRef = useRef()
  const [inputValue, setInputValue] = useMountedState(value)
  const [errorMessage, setErrorMessage] = useMountedState("")

  const handleInputChange = ({ target: { value: _inputValue } }) => {
    clearTimeout(timerRef.current)
    const { success, message } = getValidationResult(validators, _inputValue)
    setErrorMessage(message)
    setInputValue(_inputValue)
    if (success) {
      timerRef.current = setTimeout(() => {
        onChange?.(_inputValue)
      }, debounceTime)
    }
  }

  return (
    <StyledInput
      {...{
        type,
        value: inputValue,
        onChange: handleInputChange,
        errorMessage,
        ...restInputProps,
      }}
    />
  )
}

export default DebouncedInput
