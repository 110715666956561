import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { localizedF } from "@ninjaone/webapp/src/js/includes/common/utils"
import ProgressBar from "../../ProgressBar"
import Text from "../../Text"

const ProgressBarCell = ({ currentAmount, total, width, minWidth, ...props }) => {
  const label = localizedF("{{currentAmount}} of {{total}}", {
    currentAmount,
    total,
  })
  return (
    <Box display="grid" gridTemplateColumns="2fr 1fr" gap="8px" width={width} minWidth={minWidth}>
      <Flex alignItems="center">
        <ProgressBar {...props} />
      </Flex>
      <Flex alignItems="center">
        <Text type="body">{label()}</Text>
      </Flex>
    </Box>
  )
}

export default ProgressBarCell
