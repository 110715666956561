import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { ComboboxList } from "@ariakit/react"

import tokens from "@ninjaone/tokens"

import { shouldIgnoreProps } from "../utils"
import { emptyStatePopoverHeight, emptyStatePopoverWidth } from "../constants"

import Skeleton from "../../Skeleton/Skeleton"

const StyledComboboxListContainer = styled(ComboboxList, {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  padding: 0 ${tokens.spacing[2]};

  width: ${({ sameWidth }) => (sameWidth ? `100%` : emptyStatePopoverWidth)};
  height: ${emptyStatePopoverHeight};

  overflow: hidden;

  & > div {
    display: flex;
  }
`

function CustomSkeleton({ width, marginRight = "0px" }) {
  return <Skeleton {...{ width, height: "21px", marginBottom: "5.5px", marginTop: "5.5px", marginRight }} />
}

export default function SelectLoading({ isMulti, sameWidth }) {
  return (
    <StyledComboboxListContainer {...{ sameWidth }}>
      {Array.from({ length: 8 }).map((_, index) => (
        <div key={index}>
          {isMulti && <CustomSkeleton {...{ width: "21px", marginRight: tokens.spacing[2] }} />}
          {<CustomSkeleton {...{ width: isMulti ? "calc(100% - 29px)" : "100%" }} />}
        </div>
      ))}
    </StyledComboboxListContainer>
  )
}
