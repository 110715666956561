import { cloneElement, forwardRef } from "react"
import styled from "@emotion/styled"
import {
  Root as PopoverRoot,
  Anchor as PopoverAnchor,
  Trigger as PopoverTrigger,
  Content as PopoverContent,
} from "@radix-ui/react-popover"
import PropTypes from "prop-types"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import tokens from "@ninjaone/tokens"
import DatePickerCalendar from "./DatePickerCalendar"

const StyledPopoverContent = styled(PopoverContent)`
  z-index: 9999;
  padding: ${tokens.spacing[3]};
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  box-shadow: 0px 2px 4px rgba(33, 31, 51, 0.1); // TODO: replace with elevation token
`

const Trigger = forwardRef(({ triggerRenderer, ...rest }, forwardedRef) => (
  <span ref={forwardedRef}>{cloneElement(triggerRenderer(), { ...{ radixProps: { ...rest } } })}</span>
))

const DatePickerPopover = ({
  alignPopover,
  anchorRenderer,
  captionElement,
  collisionBoundary,
  disabledDays,
  footerRenderer,
  fromMonth,
  headerRenderer,
  initialMonth,
  locale,
  mode,
  month,
  onBlur,
  onDayChange,
  onDayMouseEnter,
  onFocus,
  onInteractOutside,
  onEscapeKeyDown,
  onOpenChange,
  open,
  selectedDays,
  toMonth,
  triggerRenderer,
  yearMinOffset,
  yearMaxOffset,
}) => (
  <PopoverRoot data-ninja-date-picker {...{ onOpenChange, open }}>
    {anchorRenderer && <PopoverAnchor>{anchorRenderer()}</PopoverAnchor>}

    {triggerRenderer && (
      <PopoverTrigger asChild>
        <Trigger {...{ triggerRenderer }} />
      </PopoverTrigger>
    )}

    <StyledPopoverContent
      align={alignPopover}
      collisionBoundary={collisionBoundary?.current}
      sideOffset={4}
      aria-label={localized("Date picker calendar")}
      {...{
        onEscapeKeyDown,
        onInteractOutside,
      }}
    >
      {headerRenderer?.()}

      <DatePickerCalendar
        {...{
          captionElement,
          disabledDays,
          fromMonth,
          initialMonth,
          locale,
          mode,
          month,
          onBlur,
          onDayChange,
          onDayMouseEnter,
          onFocus,
          selectedDays,
          toMonth,
          yearMinOffset,
          yearMaxOffset,
        }}
      />

      {footerRenderer?.()}
    </StyledPopoverContent>
  </PopoverRoot>
)

DatePickerPopover.defaultProps = {
  alignPopover: "start",
}

DatePickerPopover.propTypes = {
  /**
   * The preferred alignment against the anchor. May change when collisions
   * occur.
   */
  alignPopover: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * Function to render the anchor for the popover. Should only be used if the
   * trigger for the popover *should not* be the the basis for alignment of the
   * popover. The anchor should include Radix UI's popover trigger as a child
   * component.
   */
  anchorRenderer: PropTypes.func,
  /**
   * Custom element to replace the default display of the month and year.
   */
  captionElement: PropTypes.func,
  /**
   * The element used as the collision boundary. By default this is the
   * viewport, though you can provide additional element(s) to be included in
   * this check.
   */
  collisionBoundary: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  /**
   * Function to render content below the calendar.
   */
  footerRenderer: PropTypes.func,
  /**
   * Function to render content above the calendar.
   */
  headerRenderer: PropTypes.func,
  /**
   * The month to display in the calendar at first render. This differs from
   * the `month` prop, as it won’t re-render the calendar if its value changes.
   */
  initialMonth: PropTypes.instanceOf(Date),
  /**
   * The month to display in the calendar. This differs from the `initialMonth`
   * prop, as it causes the calendar to re-render when its value changes.
   */
  month: PropTypes.instanceOf(Date),
  /**
   * Event handler called when an interaction (pointer or focus event) happens
   * outside the bounds of the component. It can be prevented by calling
   * `event.preventDefault`.
   */
  onInteractOutside: PropTypes.func,
  /**
   * Event handler called when the escape key is down.
   * It can be prevented by calling `event.preventDefault`.
   */
  onEscapeKeyDown: PropTypes.func,
  /**
   * Event handler called when the open state of the popover changes.
   */
  onOpenChange: PropTypes.func,
  /**
   * The controlled state of the Popover.
   */
  open: PropTypes.bool,
  /**
   * Function to render the trigger for the popover.
   */
  triggerRenderer: PropTypes.func,
}

export default DatePickerPopover
