const faNinjaControl = {
  prefix: "fac",
  iconName: "ninja-control",
  icon: [
    111,
    95,
    [],
    "0101",
    `M92.28,87.08l-41.67,0l0,7.23l41.67,0l0,-7.23Zm-22.13,-64.68l0,-22.4l-70.15,0l0,48l32.76,0l0,29.57l77.36,0l0,-55.17l-39.97,0Zm32.75,47.95l-62.9,0l-0,-40.73l62.9,0l0,40.73Z`,
  ],
}

export default faNinjaControl
