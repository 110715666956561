import { prop } from "ramda"
import PropTypes from "prop-types"
import { useEffect, useMemo, useRef } from "react"
import { Select, TableFilterButton } from "@ninjaone/components"
import { noop } from "@ninjaone/utils"
import { localized } from "js/includes/common/utils"
import OutsideClickAlerter from "./OutsideClickAlerter"

const MultiSelectTableFilter = ({
  onSelect,
  options,
  value: filters,
  labelToken,
  isRemovable,
  alwaysShowRemove,
  openOnMount,
  onRemove,
  onDropdownClose = noop,
  onDropdownOpen = noop,
  hideLabelOnSingleSelect,
  disabled,
}) => {
  const buttonWrapperRef = useRef()
  const formattedOptions = useMemo(
    () => options.map(({ label, value, disabled }) => ({ labelText: label, value, disabled })),
    [options],
  )

  useEffect(() => {
    if (openOnMount) {
      window.requestAnimationFrame(() =>
        buttonWrapperRef.current?.dispatchEvent(new MouseEvent("pointerdown", { bubbles: true })),
      )
    }
  }, [openOnMount])

  return (
    <OutsideClickAlerter handleClickOutside={onDropdownClose}>
      <Select
        size="sm"
        triggerAriaLabel={labelToken}
        buttonRenderer={() => (
          <div ref={buttonWrapperRef}>
            <TableFilterButton
              {...{
                handleClick: e => e.stopPropagation(),
                labelToken,
                filters,
                isRemovable,
                alwaysShowRemove,
                onRemove,
                renderAsDiv: true,
                hideLabelOnSingleSelect,
                disabled,
              }}
            />
          </div>
        )}
        {...{
          onChange: onSelect,
          options: formattedOptions,
          value: filters.map(prop("value")),
          popoverProps: {
            portal: false,
          },
          disabled,
          onOpenChange: openState => {
            openState && onDropdownOpen()
          },
          triggerAriaLabel: labelToken && localized(labelToken),
        }}
      />
    </OutsideClickAlerter>
  )
}

MultiSelectTableFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  labelToken: PropTypes.string.isRequired,
  isRemovable: PropTypes.bool,
  alwaysShowRemove: PropTypes.bool,
  openOnMount: PropTypes.bool,
  onRemove: PropTypes.func,
  onDropdownClose: PropTypes.func,
  onDropdownOpen: PropTypes.func,
  hideLabelOnSingleSelect: PropTypes.bool,
}

export default MultiSelectTableFilter
