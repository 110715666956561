import { isEmpty } from "ramda"
import { Select, TableFilterButton } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

const WithDropdown = ({ value, options = [], onChange, defaultValue }) => {
  const valueOption = isEmpty(value) ? defaultValue : value
  const option = options.find(option => option.value === valueOption)
  const getSelectedLabel = (options, selectedValue) => {
    const { labelToken, labelText } = options.find(o => o.value === selectedValue)
    return labelToken ? localized(labelToken) : labelText
  }

  return (
    <Select
      {...{
        size: "sm",
        buttonRenderer: () => (
          <TableFilterButton
            {...{
              renderAsDiv: true,
              hideLabelOnSingleSelect: true,
              labelToken: option?.labelToken ?? "",
              handleClick: e => e.stopPropagation(),
              ...(valueOption && { filters: [{ label: getSelectedLabel(options, valueOption) }] }),
            }}
          />
        ),
        onChange,
        options,
        value: valueOption,
        popoverProps: {
          portal: false,
        },
      }}
    />
  )
}

export default WithDropdown
