export const lNumber = (
  value,
  {
    language,
    minRound,
    maxRound,
    styleType,
    currency,
    currencySign,
    unit,
    notation,
    compactDisplay,
    signDisplay,
    useGrouping,
  } = {},
) => {
  const currencyType = styleType === "currency" ? (!!currency ? currency : "USD") : undefined

  return new Intl.NumberFormat(language, {
    minimumFractionDigits: minRound,
    maximumFractionDigits: maxRound,
    style: styleType,
    currency: currencyType,
    currencySign,
    unit,
    notation,
    compactDisplay,
    signDisplay,
    useGrouping,
  }).format(value)
}
