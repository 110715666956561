import { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import {
  CircleCheckIcon,
  CloseIcon,
  ExternalLinkIconLight,
  InfoCircleIcon,
  InputErrorIcon,
  TriangleExclamationDuotoneSvg,
} from "@ninjaone/icons"

import tokens from "@ninjaone/tokens"

import { Flex, Box, StyledSpan, StyledAnchor } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { localizationKey, localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

import Text from "./Text"
import IconButton from "./IconButton"

const VARIANTS = {
  DANGER: "danger",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
}

const getStylesByVariant = ({ theme, variant }) => {
  switch (variant) {
    case VARIANTS.DANGER:
      return `
        background-color: ${theme.colorAlertErrorWeak};
      `
    case VARIANTS.SUCCESS:
      return `
        background-color: ${theme.colorAlertSuccessWeak};
      `
    case VARIANTS.WARNING:
      return `
        background-color: ${theme.colorAlertWarningWeakest};
      `
    case VARIANTS.INFO:
    default:
      return `
        background-color: ${theme.colorAlertInfoWeak};
      `
  }
}

const getIconColorByVariant = ({ theme, variant }) => {
  switch (variant) {
    case VARIANTS.DANGER:
      return `
        color: ${theme.colorAlertError};
      `
    case VARIANTS.SUCCESS:
      return `
        color: ${theme.colorAlertSuccess};
      `
    case VARIANTS.WARNING:
      return `
        color: ${theme.colorAlertWarning};
      `
    case VARIANTS.INFO:
    default:
      return `
        color: ${theme.colorAlertInfo};
      `
  }
}

const getIconByVariant = variant => {
  switch (variant) {
    case VARIANTS.DANGER:
      return <InputErrorIcon label={localized("Error")} />

    case VARIANTS.SUCCESS:
      return <CircleCheckIcon label={localized("Success")} />

    case VARIANTS.WARNING:
      return <TriangleExclamationDuotoneSvg height={16} width={16} label={localized("Warning")} />

    default:
      return <InfoCircleIcon label={localized("Information")} />
  }
}

const SectionContainer = styled(Flex)`
  width: 100%;
  padding-right: ${tokens.spacing[3]};
  border-radius: ${tokens.borderRadius[1]};

  ${getStylesByVariant};

  button {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
`

const ContentContainer = styled(Flex)`
  padding: ${tokens.spacing[3]};
`

const IconContainer = styled(Box)`
  margin-right: ${tokens.spacing[2]};
  margin-top: 2px;

  align-items: ${({ iconAlign }) => `${iconAlign}`};

  display: flex;

  ${getIconColorByVariant};
`

const StyledIconButton = styled(IconButton)`
  height: auto;
  width: auto;

  align-self: self-start;

  padding: 0;
  padding-top: ${tokens.spacing[3]};
`

const StyledExternalLinkIcon = styled(ExternalLinkIconLight)`
  margin-left: 2px;
`

const AlertMessage = ({
  children,
  dismissable,
  iconAlign = "baseline",
  labelToken,
  labelTokenArgs,
  link,
  linkRenderer,
  linkToken = localizationKey("Learn more"),
  titleToken,
  titleTokenArgs,
  variant,
}) => {
  const [isDismissed, setIsDismissed] = useState(false)

  const handleDismissBanner = () => {
    setIsDismissed(true)
  }

  if (isDismissed) return null

  return (
    <SectionContainer {...{ variant, alignItems: "center", justifyContent: "space-between" }}>
      <ContentContainer>
        <IconContainer {...{ variant, iconAlign }}>{getIconByVariant(variant)}</IconContainer>
        <Box>
          {titleToken && (
            <Text token={titleToken} tokenArgs={titleTokenArgs} extraBold size="sm" color="colorTextStrong" textWrap />
          )}

          <Text size="sm" color="colorTextStrong" textWrap>
            {children ?? localized(labelToken, labelTokenArgs)}

            {link && (
              <StyledSpan marginLeft={tokens.spacing[1]}>
                <StyledAnchor href={link} target="_blank" rel="noopener noreferrer">
                  {localized(linkToken)}

                  <StyledExternalLinkIcon size="sm" label={localized("External Link")} />
                </StyledAnchor>
              </StyledSpan>
            )}

            {linkRenderer && <StyledSpan marginLeft={tokens.spacing[1]}>{linkRenderer()}</StyledSpan>}
          </Text>
        </Box>
      </ContentContainer>

      {dismissable && (
        <StyledIconButton {...{ size: "sm", onClick: handleDismissBanner, "aria-label": "Dismiss" }}>
          <CloseIcon />
        </StyledIconButton>
      )}
    </SectionContainer>
  )
}

AlertMessage.propTypes = {
  /**
   * The variant of the alert message, which determines its styling.
   * Can be one of: "danger", "warning", "success", "info".
   */
  variant: PropTypes.oneOf(["danger", "warning", "success", "info"]),
  /**
   * The token for the label text of the alert message.
   */
  labelToken: PropTypes.string,
  /**
   * The token for the title text of the alert message.
   */
  titleToken: PropTypes.string,
  /**
   * The URL for the link associated with the alert message.
   */
  link: PropTypes.string,
  /**
   * The content to be displayed inside the alert message.
   */
  children: PropTypes.node,
  /**
   * The alignment of the icon within the alert message.
   */
  iconAlign: PropTypes.string,
  /**
   * This enables the functionality to dismiss the alert message.
   */
  dismissable: PropTypes.bool,
}

export default AlertMessage
