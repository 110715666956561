import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { Text } from "@ninjaone/components"

const StyledWrapper = styled.label`
  display: flex;
  align-items: center;
  &:not(:first-of-type) {
    margin-top: ${sizer(2)};
  }
`

const StyledRadio = styled.input`
  && {
    margin: ${sizer(0, 3, 0, 0)};
  }
  width: 14px;
  height: 14px;

  appearance: none;
  border-radius: 50%;
  accent-color: ${({ theme }) => theme.color.primary[100]};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.color.text};

  &:checked {
    border: 2px solid white;
    background: ${({ theme }) => theme.color.primary[100]};
  }
`

const Radio = ({ value, name, checked, onChange, label, labelToken }) => {
  return (
    <StyledWrapper>
      <StyledRadio
        {...{
          type: "radio",
          value,
          name,
          checked,
          onChange,
        }}
      />
      {labelToken ? <Text size="sm" token={labelToken} /> : label}
    </StyledWrapper>
  )
}

export default Radio
