import { useMemo } from "react"
import { equals, find, propSatisfies } from "ramda"
import SingleSelectTableFilter from "@ninjaone/webapp/src/js/includes/components/SingleSelectTableFilter"

const SingleSelectFilter = ({
  filter: {
    name,
    value: filterValue,
    labelToken,
    defaultValue,
    componentProps: { options, isRemovable = !defaultValue },
  },
  updateFilter,
  alwaysShowRemove,
  openOnMount,
  onRemove,
  onDropdownClose,
  disabled,
}) => {
  const onSelect = value => updateFilter(name, value)

  const selectedValue = useMemo(() => find(propSatisfies(equals(filterValue), "value"), options), [
    options,
    filterValue,
  ])

  return (
    <SingleSelectTableFilter
      {...{
        labelToken,
        options,
        onSelect,
        value: selectedValue,
        isRemovable,
        alwaysShowRemove,
        openOnMount,
        onDropdownClose,
        onRemove: () => {
          onRemove?.(name)
          updateFilter(name, "")
        },
        disabled,
      }}
    />
  )
}

export default SingleSelectFilter
