import { useCallback } from "react"
import NumberRangeTableFilter from "@ninjaone/webapp/src/js/includes/components/NumberRangeTableFilter"

const NumberRangeFilter = ({
  filter: { name, value, labelToken, defaultValue, componentProps: { isRemovable = !defaultValue, min, max } = {} },
  updateFilter,
  alwaysShowRemove,
  onRemove,
  openOnMount,
  disabled,
}) => {
  const onChange = useCallback(
    value => {
      updateFilter(name, value)
    },
    [updateFilter, name],
  )

  return (
    <NumberRangeTableFilter
      {...{
        min,
        max,
        labelToken,
        value,
        isRemovable,
        alwaysShowRemove,
        onSelect: onChange,
        openOnMount,
        onRemove: () => {
          onRemove?.(name)
          updateFilter(name)
        },
        disabled,
      }}
    />
  )
}

export default NumberRangeFilter
