import { useCallback } from "react"
import InputWithUnitDropdownTableFilter from "@ninjaone/webapp/src/js/includes/components/InputWithUnitDropdownTableFilter/InputWithUnitDropdownTableFilter"

const InputWithUnitDropdownFilter = ({
  filter: {
    name,
    value: filterValueList,
    labelToken,
    defaultValue,
    componentProps: {
      options,
      isRemovable = !defaultValue,
      valueSelectorKey = "value",
      titleToken,
      inputValidators,
      selectedFormatter,
      inputProps,
      dropdownLabelToken,
    },
  },
  updateFilter,
  alwaysShowRemove,
  openOnMount,
  onRemove,
  onDropdownClose,
  disabled,
}) => {
  const onChange = useCallback(
    value => {
      updateFilter(name, value)
    },
    [name, updateFilter],
  )

  return (
    <InputWithUnitDropdownTableFilter
      {...{
        labelToken,
        options,
        value: filterValueList,
        onChange,
        isRemovable,
        alwaysShowRemove,
        openOnMount,
        onDropdownClose,
        onRemove: () => {
          onRemove?.(name)
          updateFilter(name)
        },
        titleToken,
        inputValidators,
        dropdownLabelToken,
        selectedFormatter,
        inputProps,
        disabled,
      }}
    />
  )
}

export default InputWithUnitDropdownFilter
