import React, { memo, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Box } from "js/includes/components/Styled"
import { isEscapeKey, isTabKey } from "../common/utils"

const OutsideClickAlerter = memo(
  ({
    handleClickOutside,
    activeScrollEventListener = false,
    children,
    css,
    style,
    className,
    useCapture = false,
    useClick = false,
    useKeydown = true,
    usePointerDown = true,
    ...otherProps
  }) => {
    const ref = useRef(null)

    useEffect(() => {
      const handleExit = e => {
        const pressedEscapeOrTabKey = isEscapeKey(e) || isTabKey(e)
        if ((ref && !ref.current?.contains(e.target)) || pressedEscapeOrTabKey) {
          handleClickOutside(e)
        }
      }
      activeScrollEventListener && window.addEventListener("wheel", handleExit)

      if (useClick) {
        document.addEventListener("click", handleExit, useCapture)
      } else {
        document.addEventListener("mousedown", handleExit, useCapture)
        if (usePointerDown) {
          document.addEventListener("pointerdown", handleExit)
        }
      }

      if (useKeydown) {
        document.addEventListener("keydown", handleExit)
      }
      return () => {
        activeScrollEventListener && window.removeEventListener("wheel", handleExit)
        if (useClick) {
          document.removeEventListener("click", handleExit, useCapture)
        } else {
          document.removeEventListener("mousedown", handleExit, useCapture)
          if (usePointerDown) {
            document.removeEventListener("pointerdown", handleExit)
          }
        }

        if (useKeydown) {
          document.removeEventListener("keydown", handleExit)
        }
      }
    }, [handleClickOutside, useCapture, activeScrollEventListener, useClick, useKeydown, usePointerDown])

    return <Box {...{ ref, css, style, className, ...otherProps }}>{children}</Box>
  },
)

OutsideClickAlerter.propTypes = {
  children: PropTypes.element.isRequired,
  handleClickOutside: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  css: PropTypes.object,
  useCapture: PropTypes.bool,
  useClick: PropTypes.bool,
  useKeydown: PropTypes.bool,
  usePointerDown: PropTypes.bool,
}

export default OutsideClickAlerter
