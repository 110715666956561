import React, { memo } from "react"
import { useTheme } from "@emotion/react"
import { Text } from "@ninjaone/components"
import { noRowsRenderer, isNotNilOrEmpty } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { map, is } from "ramda"

export default memo(
  ({
    isMulti,
    selected,
    value,
    options,
    loading,
    onSelect,
    onRowClick,
    rowHeight,
    rowRenderer,
    searchableKey,
    getOptionValue,
    getOptionLabel,
    valueSelectorKey,
    noRowsRendererToken,
    optionsWrap,
    isRowFocused,
  }) => {
    const theme = useTheme()
    const hasValue = isNotNilOrEmpty(value)

    const createList = list => {
      return map(item => {
        const value = getOptionValue?.(item) ?? item[valueSelectorKey]
        const label =
          getOptionLabel?.(item) || item[searchableKey] || (is(Function, item.label) ? item.label() : item.label)
        const isSelected = isMulti
          ? selected.some(s => s?.[valueSelectorKey] === value)
          : value === selected?.[valueSelectorKey]
        const backgroundColor = isSelected && hasValue ? theme.colorForegroundSelected : "transparent"

        return item.options ? (
          <Box key={value} width="100%" margin={[2, 0]} {...{ backgroundColor }}>
            <Box
              className={isRowFocused(item) ? "focused-row" : ""}
              height={15}
              width="100%"
              display="flex"
              alignItems="center"
              padding={[0, 2]}
            >
              <Text color="colorTextWeakest" type="bodyXs">
                {label.toUpperCase()}
              </Text>
            </Box>
            {createList(item.options)}
          </Box>
        ) : (
          <Box
            key={value}
            height={rowHeight}
            width="100%"
            display="flex"
            alignItems="center"
            padding={[optionsWrap ? 4 : 0, 1]}
            className={`local-options-row ${isRowFocused(item) ? "focused-row" : ""}`}
            {...{ backgroundColor }}
            onClick={event => {
              onRowClick(item)
            }}
            role="option"
          >
            {rowRenderer(item)}
          </Box>
        )
      }, list)
    }

    return !loading && options?.length ? (
      <Box width="100%" maxHeight={300} overflowY="auto">
        {createList(options)}
      </Box>
    ) : (
      <Box
        height={rowHeight}
        width="100%"
        display="flex"
        alignItems="center"
        padding={[0, 2]}
        color={theme.colorTextWeak}
      >
        {noRowsRenderer(noRowsRendererToken, loading, "p-t-md")()}
      </Box>
    )
  },
)
