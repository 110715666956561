import { cond, pathOr, is, evolve, T, compose, pick, keys, identity } from "ramda"
import { renameKeys } from "js/includes/common/utils/ssrAndWebUtils"
import { colors as themeColors, spacing } from "js/includes/common/theme"
import defaultTheme from "js/includes/common/theme/theme-ninja-blue/light"

const fontSizes = [10, 12, 14, 16, 18, 20, 22, 24]

const ignoreProps = new Set([
  "position",
  "top",
  "right",
  "bottom",
  "left",
  "zIndex",
  "border",
  "borderStyle",
  "borderWidth",
  "borderRadius",
  "borderColor",
  "borderBottom",
  "width",
  "height",
  "minWidth",
  "maxWidth",
  "minHeight",
  "maxHeight",
  "verticalAlign",
  "overflow",
  "overflowWrap",
  "overflowX",
  "overflowY",
  "size",
  "color",
  "backgroundColor",
  "opacity",
  "display",
  "flexDirection",
  "alignItems",
  "justifyContent",
  "flexWrap",
  "justifyItems",
  "alignContent",
  "flexGrow",
  "flexShrink",
  "flexBasis",
  "justifySelf",
  "alignSelf",
  "order",
  "fontFamily",
  "fontSize",
  "lineHeight",
  "letterSpacing",
  "textAlign",
  "fontStyle",
  "fontWeight",
  "margin",
  "padding",
  "marginBottom",
  "marginLeft",
  "marginTop",
  "marginRight",
  "paddingBottom",
  "paddingLeft",
  "paddingTop",
  "paddingRight",
  "marginX",
  "marginY",
])

export const shouldForwardProp = prop => !ignoreProps.has(prop)

const setValueOrString = cond([
  [is(Number), val => `${val}px`],
  [T, identity],
])

const setFontSizeValueOrString = val => {
  return cond([
    [is(Number), v => fontSizes[v] || `${v}px`],
    [T, identity],
  ])(val)
}

const setSpaceValueOrString = val => {
  return cond([
    [is(Array), v => spacing(...v)],
    [is(Number), spacing],
    [T, identity],
  ])(val)
}

//In case the theme is not set, default to theme-ninja-blue theme
const setColorValueOrString = (val, palette = defaultTheme.palette, theme) => {
  return cond([
    [is(Array), v => pathOr("inherit", v, palette)],
    [is(String), () => themeColors[val] || theme?.[val] || val],
    [T, identity],
  ])(val)
}

const setShadowValueOrString = (val, theme) => {
  return cond([
    [is(Number), val => `${val}px`],
    [is(String), () => theme?.[val] || val],
    [T, identity],
  ])(val)
}

const transformToCss = (transformation, props) => compose(evolve(transformation), pick(keys(transformation)))(props)

export const position = props => {
  const transformation = {
    position: setValueOrString,
    top: setValueOrString,
    right: setValueOrString,
    bottom: setValueOrString,
    left: setValueOrString,
    direction: setValueOrString,
    zIndex: identity,
  }
  return transformToCss(transformation, props)
}

export const border = props => {
  const { theme } = props
  const { palette } = theme

  const transformation = {
    border: setValueOrString,
    borderStyle: setValueOrString,
    borderWidth: setValueOrString,
    borderRadius: setValueOrString,
    borderTopLeftRadius: setValueOrString,
    borderBottomLeftRadius: setValueOrString,
    borderTopRighttRadius: setValueOrString,
    borderBottomRightRadius: setValueOrString,
    borderColor: val => setColorValueOrString(val, palette, theme),
    borderTop: setValueOrString,
    borderBottom: setValueOrString,
    borderLeft: setValueOrString,
    borderRight: setValueOrString,
    borderTopWidth: setValueOrString,
    borderTopStyle: setValueOrString,
    borderTopColor: val => setColorValueOrString(val, palette, theme),
    borderRightWidth: setValueOrString,
    borderRightStyle: setValueOrString,
    borderRightColor: val => setColorValueOrString(val, palette, theme),
    borderBottomWidth: setValueOrString,
    borderBottomStyle: setValueOrString,
    borderBottomColor: val => setColorValueOrString(val, palette, theme),
    borderLeftWidth: setValueOrString,
    borderLeftStyle: setValueOrString,
    borderLeftColor: val => setColorValueOrString(val, palette, theme),
  }
  return transformToCss(transformation, props)
}

export const layout = props => {
  const transformation = {
    width: setValueOrString,
    height: setValueOrString,
    minWidth: setValueOrString,
    maxWidth: setValueOrString,
    minHeight: setValueOrString,
    maxHeight: setValueOrString,
    verticalAlign: setValueOrString,
    overflow: setValueOrString,
    overflowWrap: setValueOrString,
    overflowX: setValueOrString,
    overflowY: setValueOrString,
    cursor: identity,
    resize: setValueOrString,
    transform: setValueOrString,
    pointerEvents: setValueOrString,
    size: val => ({
      width: setValueOrString(val),
      height: setValueOrString(val),
    }),
  }
  return transformToCss(transformation, props)
}

export const color = props => {
  const { theme } = props
  const { palette } = theme

  const transformation = {
    color: val => setColorValueOrString(val, palette, theme),
    backgroundColor: val => setColorValueOrString(val, palette, theme),
    opacity: identity,
  }

  return transformToCss(transformation, props)
}

export const flexbox = props => {
  const transformation = {
    display: setValueOrString,
    flexDirection: setValueOrString,
    alignItems: setValueOrString,
    justifyContent: setValueOrString,
    flexWrap: setValueOrString,
    justifyItems: setValueOrString,
    alignContent: setValueOrString,
    flexGrow: identity,
    flexShrink: identity,
    flexBasis: identity,
    justifySelf: setValueOrString,
    alignSelf: setValueOrString,
    order: setValueOrString,
    flex: identity,
  }
  return transformToCss(transformation, props)
}

export const grid = props => {
  const transformation = {
    gridTemplateAreas: identity,
    gridTemplateColumns: identity,
    gridTemplateRows: identity,
    gridRowGap: setValueOrString,
    gridColumnGap: setValueOrString,
    gridGap: setValueOrString,
    gridColumnStart: identity,
    gridColumnEnd: identity,
    gridRowStart: identity,
    gridRowEnd: identity,
    gridAutoColumns: identity,
    gridAutoRows: identity,
    gridAutoFlow: identity,
    gridArea: identity,
    gridColumn: identity,
    gridRow: identity,
    placeItems: identity,
    placeContent: identity,
  }
  return transformToCss(transformation, props)
}

export const typography = props => {
  const transformation = {
    fontFamily: setValueOrString,
    fontSize: setFontSizeValueOrString,
    lineHeight: setValueOrString,
    letterSpacing: setValueOrString,
    textAlign: setValueOrString,
    fontStyle: setValueOrString,
    fontWeight: identity,
    whiteSpace: setValueOrString,
    textDecoration: identity,
    wordBreak: setValueOrString,
    textOverflow: identity,
    textWrap: setValueOrString,
    wordWrap: setValueOrString,
    hyphens: setValueOrString,
    overflowWrap: setValueOrString,
  }
  return transformToCss(transformation, props)
}

export const space = props => {
  const transformation = {
    margin: setSpaceValueOrString,
    padding: setSpaceValueOrString,
    marginBottom: setSpaceValueOrString,
    marginLeft: setSpaceValueOrString,
    marginTop: setSpaceValueOrString,
    marginRight: setSpaceValueOrString,
    paddingBottom: setSpaceValueOrString,
    paddingLeft: setSpaceValueOrString,
    paddingTop: setSpaceValueOrString,
    paddingRight: setSpaceValueOrString,
    marginX: setSpaceValueOrString,
    marginY: setSpaceValueOrString,
    gap: setSpaceValueOrString,
  }
  return transformToCss(transformation, props)
}

export const shadow = props => {
  const { theme } = props

  const transformation = {
    boxShadow: val => setShadowValueOrString(val, theme),
  }
  return transformToCss(transformation, props)
}

export const hover = props => {
  const transformation = {
    cursor: setValueOrString,
    color: setColorValueOrString,
    backgroundColor: setColorValueOrString,
    borderColor: setColorValueOrString,
  }
  return {
    "&:hover": {
      ...transformToCss(
        transformation,
        renameKeys({
          hoverCursor: "cursor",
          hoverColor: "color",
          hoverBackgroundColor: "backgroundColor",
          hoverBorderColor: "borderColor",
        })(props),
      ),
    },
  }
}
