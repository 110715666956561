import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden"
import PropTypes from "prop-types"
import { InputErrorIcon } from "@ninjaone/icons"
import Tooltip from "../Tooltip"

const ErrorIconTooltip = ({
  ariaError,
  ariaId,
  ariaLive = "polite",
  error,
  tooltipAlignment,
  tooltipPosition,
  triggerAsChild,
}) => (
  <Tooltip
    isErrorMessage
    label={error}
    sideOffset={4}
    align={tooltipAlignment}
    position={tooltipPosition}
    {...{ triggerAsChild }}
  >
    <VisuallyHidden id={`${ariaId}-error`} aria-live={ariaLive}>
      {ariaError ?? error}
    </VisuallyHidden>

    <InputErrorIcon color="colorBackgroundCtaDanger" />
  </Tooltip>
)

ErrorIconTooltip.propTypes = {
  ariaError: PropTypes.string,
  ariaId: PropTypes.string.isRequired,
  ariaLive: PropTypes.oneOf(["assertive", "polite"]),
  error: PropTypes.string.isRequired,
  tooltipAlignment: PropTypes.oneOf(["start", "center", "end"]),
  tooltipPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  triggerAsChild: PropTypes.bool,
}

export default ErrorIconTooltip
