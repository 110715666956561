import { useState } from "react"
import moment from "moment"
import PropTypes from "prop-types"
import { always, cond, isNil, T } from "ramda"

import { TableFilterButton } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"

import { date } from "js/includes/common/utils"
import DateRangePicker from "js/includes/components/DateRangePicker"
import OutsideClickAlerter from "js/includes/components/OutsideClickAlerter"
import { Box, StyledSpan } from "js/includes/components/Styled"

const DateRangeSelectTableFilter = ({
  onSelect,
  value,
  labelToken,
  isRemovable,
  alwaysShowRemove,
  onRemove,
  openOnMount = false,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(openOnMount)
  const [selected, setSelected] = useState({ from: value?.from, to: value?.to })

  const formatFilters = cond([
    [isNil, always([])],
    [T, ({ from, to }) => (from ? [{ label: `${date(from)}${to ? `-${date(to)}` : ""}` }] : [])],
  ])

  return (
    <Box width="fit-content">
      <OutsideClickAlerter handleClickOutside={() => setIsOpen(false)}>
        <Box position="relative">
          <TableFilterButton
            {...{
              hideTooltip: isOpen,
              handleClick: () => setIsOpen(isOpen => !isOpen),
              labelToken,
              filters: formatFilters(value),
              isRemovable,
              alwaysShowRemove,
              onRemove: () => {
                onRemove?.()
                isOpen && setIsOpen(false)
              },
              disabled,
            }}
          />
          <Box position="absolute" top={`calc(100% + ${sizer(1)})`} zIndex={1000}>
            {isOpen && (
              <StyledSpan
                backgroundColor="white"
                padding={1}
                display="inline-block"
                width={360}
                border="1px solid"
                borderColor="ninjaLight"
                borderRadius={4}
              >
                <DateRangePicker
                  className="date-range-filter"
                  selectedDays={value}
                  dateRangeSelector={({ from, to }) => {
                    const newSelected = {
                      ...selected,
                      from:
                        from &&
                        moment(from)
                          .startOf("day")
                          .toDate(),
                      to:
                        to &&
                        moment(to)
                          .endOf("day")
                          .toDate(),
                    }

                    setSelected(newSelected)
                    if (newSelected.from && newSelected.to) {
                      onSelect(newSelected)
                    }
                  }}
                />
              </StyledSpan>
            )}
          </Box>
        </Box>
      </OutsideClickAlerter>
    </Box>
  )
}

DateRangeSelectTableFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.shape({
    from: PropTypes.object,
    to: PropTypes.object,
  }),
  labelToken: PropTypes.string.isRequired,
  isRemovable: PropTypes.bool,
  alwaysShowRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  openOnMount: PropTypes.bool,
}

export default DateRangeSelectTableFilter
