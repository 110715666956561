import PropTypes from "prop-types"

import { sizer, isRequiredIf } from "@ninjaone/utils"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"

import { DangerButtonGroup, DefaultButtonGroup } from "./ButtonGroup"
import Modal from "./Modal"

export default function ConfirmationModal({
  type = "default",
  backgroundClassName,
  titleToken,
  titleText,
  titleTokenArgs,
  children,
  actionToken,
  closeToken,
  onConfirm,
  onCancel,
  unmount,
  disabled,
  size = "sm",
  descriptionToken,
  descriptionText,
  DescriptionComponent,
  showActionButton = true,
  showClose = true,
  closeAction,
  textWrapLineLimit = 2,
  focusTrapDisabled,
}) {
  return (
    <Modal
      data-testid="confirm"
      {...{
        backgroundClassName,
        titleGroup: {
          titleToken,
          titleText,
          titleTokenArgs,
          color: "colorTextStrong",
          descriptionToken,
          descriptionText,
          DescriptionComponent,
          textWrapLineLimit,
        },
        focusTrapDisabled,
        unmount,
        size,
        closeAction,
        ...{
          ...(showActionButton && {
            buttonRenderer: () => (
              <Box width="100%" paddingTop={sizer(4)}>
                {type === "danger" ? (
                  <DangerButtonGroup
                    {...{
                      onCancel: e => onCancel?.({ e, unmount }) ?? unmount(),
                      dangerActionToken: actionToken,
                      closeToken,
                      onConfirm: e => onConfirm({ e, unmount }),
                      disabled,
                    }}
                  />
                ) : (
                  <DefaultButtonGroup
                    {...{
                      unmount,
                      actionToken,
                      closeToken,
                      onConfirm: e => onConfirm({ e, unmount }),
                      disabled,
                      showClose,
                    }}
                  />
                )}
              </Box>
            ),
          }),
        },
      }}
    >
      <Box color="colorTextStrong">{children}</Box>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  type: PropTypes.oneOf(["default", "danger"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  titleToken: PropTypes.string,
  titleText: PropTypes.string,
  titleTokenArgs: PropTypes.object,
  textWrapLineLimit: PropTypes.number,
  actionToken: isRequiredIf(
    PropTypes.string,
    props => props.showActionButton,
    "`actionToken` is required if `showActionButton` is true.",
  ),
  showActionButton: PropTypes.bool,
  closeToken: PropTypes.string,
  onConfirm: isRequiredIf(
    PropTypes.func,
    props => props.showActionButton,
    "`onConfirm` is required if `showActionButton` is true.",
  ),
  unmount: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  closeAction: PropTypes.func,
}
