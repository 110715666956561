import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { escapeRegExp } from "@ninjaone/webapp/src/js/includes/common/utils"

const StyledMark = styled.mark`
  padding: 0;
  background-color: ${({ theme }) => theme.colorForegroundTextHighlight};
`

const HighLighter = ({ text, highlight, highlightedItemClass }) => {
  const parts = text ? text.split(new RegExp(`(${escapeRegExp(highlight)})`, "gi")).filter(Boolean) : []
  return (
    <>
      {parts.map((part, i) => {
        return part.toLowerCase() === highlight.toLowerCase() ? (
          <StyledMark key={i} className={highlightedItemClass}>
            {part}
          </StyledMark>
        ) : (
          <span key={i}>{part}</span>
        )
      })}
    </>
  )
}

export default HighLighter

HighLighter.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  highlightedItemClass: PropTypes.string,
}
