import React from "react"
import Modal from "js/includes/components/Modal"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { Form, FormControl, FormGroup, Col, ControlLabel } from "react-bootstrap"
import { useForm } from "js/includes/common/hooks"

const format = str => str.trim().toLowerCase()

const SaveFiltersModal = ({ saveFilters, savedFilters, unmount }) => {
  const {
    values: { name, description },
    validation,
    validateForm,
    onChange,
  } = useForm({
    fields: {
      name: "",
      description: "",
    },
    validate: {
      name: name => {
        const isEmpty = !name.trim()
        const isDuplicate = savedFilters.some(filter => format(filter.name) === format(name))
        return {
          success: !isEmpty && !isDuplicate,
          message: isDuplicate ? localized("This name already exists") : "",
        }
      },
      description: val => ({ success: !!val, message: "" }),
    },
  })

  return (
    <Form
      horizontal
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        const isValidated = validateForm()
        if (isValidated) {
          saveFilters({ name, description })
          unmount()
        }
      }}
    >
      <Modal
        submittable
        title={localizationKey("Filter Set")}
        saveText={localizationKey("Save")}
        closeText={localizationKey("Cancel")}
        close={unmount}
      >
        <FormGroup validationState={validation.success.name === false ? "error" : null} className="m-b-md">
          <Col xs={4} componentClass={ControlLabel}>
            {localized("Name")}
            <sup> *</sup>
          </Col>
          <Col xs={8}>
            <FormControl
              autoFocus
              required
              type="text"
              name="name"
              value={name}
              onBlur={() => validateForm(["name"])}
              placeholder={localized("Name")}
              {...{ onChange }}
            />
            <em className="invalid position-absolute no-margin">{validation.message.name}</em>
          </Col>
        </FormGroup>

        <FormGroup className="m-b-md">
          <Col xs={4} componentClass={ControlLabel}>
            {localized("Description")}
          </Col>
          <Col xs={8}>
            <FormControl
              type="text"
              name="description"
              value={description}
              placeholder={localized("(optional)")}
              {...{ onChange }}
            />
          </Col>
        </FormGroup>
      </Modal>
    </Form>
  )
}

export default SaveFiltersModal
