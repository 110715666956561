import { PureComponent } from "react"
import tokens from "@ninjaone/tokens"
import WebAppStickyPopover from "@ninjaone/webapp/src/js/includes/components/Popover/StickyPopover"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { Link } from "@ninjaone/components"

const ENTER_DELAY = 500
const EXIT_DELAY = 200

class StickyPopover extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      hovered: false,
    }

    this.removeOnScroll = this.removeOnScroll.bind(this)
    this.handleLeave = this.handleLeave.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
  }

  hoverTimeout = null
  leaveTimeout = null
  mouseOver = false

  componentDidMount() {
    this._isMounted = true
  }

  removeOnScroll() {
    this.state.hovered && this.mouseOver && this.handleLeave()
  }

  handleLeave() {
    const _this = this
    window.removeEventListener("scroll", _this.removeOnScroll)

    _this.mouseOver = false
    clearTimeout(_this.hoverTimeout)

    _this.leaveTimeout = setTimeout(() => {
      !_this.mouseOver && this._isMounted && _this.setState({ hovered: false })
    }, EXIT_DELAY)
  }

  handleEnter() {
    const _this = this
    _this.mouseOver = true

    clearTimeout(_this.leaveTimeout)
    window.removeEventListener("scroll", _this.removeOnScroll)

    _this.hoverTimeout = setTimeout(() => {
      window.addEventListener("scroll", _this.removeOnScroll, true)
      _this.mouseOver && this._isMounted && _this.setState({ hovered: true })
    }, ENTER_DELAY)
  }

  componentWillUnmount() {
    this._isMounted = false
    clearTimeout(this.hoverTimeout)
    clearTimeout(this.leaveTimeout)
    window.removeEventListener("scroll", this.removeOnScroll)
  }

  render() {
    const {
      PopoverContentComponent,
      children,
      onClick,
      rel,
      href,
      target,
      style,
      innerStyle,
      showStickyPopoverOnFocus,
      iconTrigger,
      keepPopoverOpenOnHover,
      renderTextAsLink,
      ...rest
    } = this.props
    const { hovered } = this.state

    const trigger = renderTextAsLink ? (
      <Flex alignItems="baseline" gap={tokens.spacing[1]}>
        <Link
          {...{
            onMouseOver: this.handleEnter,
            onMouseOut: this.handleLeave,
            href,
            target,
            rel,
            onClick,
          }}
        >
          {children}
        </Link>
        {iconTrigger && (
          <button
            {...{
              className: "data-table-button-link",
              onMouseEnter: this.handleEnter,
              onMouseLeave: this.handleLeave,
              onClick,
              ...(!hovered && { "data-visible-on-row-hover": true }),
            }}
          >
            {iconTrigger}
          </button>
        )}
      </Flex>
    ) : iconTrigger ? (
      <Flex alignItems="baseline" gap={tokens.spacing[1]}>
        {children}
        <button
          {...{
            className: "data-table-button-link",
            onMouseEnter: this.handleEnter,
            onMouseLeave: this.handleLeave,
            ...(!hovered && { "data-visible-on-row-hover": true }),
          }}
        >
          {iconTrigger}
        </button>
      </Flex>
    ) : (
      <Link
        {...{
          onMouseOver: this.handleEnter,
          onMouseOut: this.handleLeave,
          href,
          target,
          rel,
          onClick,
        }}
      >
        {children}
      </Link>
    )

    return hovered || showStickyPopoverOnFocus ? (
      <WebAppStickyPopover
        {...{
          showPopoverArrow: false,
          trigger,
          style,
          innerStyle,
          ...rest,
        }}
      >
        <div {...(keepPopoverOpenOnHover && { onMouseEnter: this.handleEnter, onMouseLeave: this.handleLeave })}>
          {PopoverContentComponent}
        </div>
      </WebAppStickyPopover>
    ) : (
      trigger
    )
  }
}

export default StickyPopover
