import ReactDOM from "react-dom"
import PropTypes from "prop-types"

export default function Portal({ children, containerRef, containerId = "react-portal" }) {
  if (!containerId && !containerRef) {
    throw Error("Portal requires one of containerId or containerRef to be passed as props")
  }

  const element = containerRef || document.getElementById(containerId)
  return ReactDOM.createPortal(children, element)
}

Portal.propTypes = {
  containerId: PropTypes.string,
  containerRef: PropTypes.object,
}
