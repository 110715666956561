import { borderRadius } from "./borderRadius"
import { grid } from "./grid"
import { spacing, spacingBase } from "./spacing"
import { typography } from "./typography"

export { borderRadius, spacing, spacingBase, typography }

const tokens = {
  borderRadius,
  grid,
  spacing,
  spacingBase,
  typography,
}

export default tokens
