import styled from "@emotion/styled"

import { sizer } from "@ninjaone/utils"

export const StyledInputWrapper = styled.div`
  position: relative;
`

export const StyledErrorIcon = styled.div`
  position: absolute;
  top: ${({ top }) => top ?? "1px"};
  right: ${({ right }) => right ?? "1px"};
  bottom: 1px;
  padding-right: ${sizer(3)};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorTextDanger};
`

export const StyledIconsWithErrorContainer = styled.div`
  position: absolute;
  top: ${({ top }) => top ?? "1px"};
  right: 1px;
  bottom: 1px;
  padding-right: ${sizer(3)};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.black["065"]};
  svg {
    margin-left: ${sizer(2)};
  }
  span {
    //tooltip and error icon styling
    line-height: 1;
    svg {
      color: ${({ theme }) => theme.colorTextDanger};
      margin-left: 0px;
    }
  }
`
