import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Root, Indicator } from "@radix-ui/react-progress"

export const StyledRoot = styled(Root)`
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};

  width: 100%;
  border-radius: 36px;
  height: ${({ height }) => height || "4px"};

  position: relative;
  overflow: hidden;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);

  &:focus-visible {
    outline-offset: 0;
    border-radius: 2px;
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

export const StyledIndicator = styled(Indicator)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colorAlertNeutral : theme.colorForegroundSelectedStrongest};
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
`

function ProgressBar({ progress, height, ariaLabel, disabled }) {
  return (
    <StyledRoot {...{ height, "aria-label": ariaLabel, tabIndex: 0 }}>
      <StyledIndicator disabled={disabled} style={{ transform: `translateX(-${100 - progress}%)` }} />
    </StyledRoot>
  )
}

ProgressBar.defaultProps = {
  progress: 0,
}

ProgressBar.propTypes = {
  /**
   * The height of the progress bar (e.g., '10px').
   */
  height: PropTypes.string,
  /**
   * The progress value for the bar.
   */
  progress: PropTypes.number.isRequired,
  /**
   * The ARIA label for accessibility.
   */
  ariaLabel: PropTypes.string,
  /**
   * The disabled state for the progress bar
   */
  disabled: PropTypes.bool,
}

export default ProgressBar
