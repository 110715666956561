import PropTypes from "prop-types"

import { CloseIcon } from "@ninjaone/icons"

import Tooltip from "../Tooltip"

const ClearIconTooltip = ({ ariaLabel, label, tooltipAlignment, tooltipPosition }) => (
  <Tooltip sideOffset={4} align={tooltipAlignment} position={tooltipPosition} {...{ label }}>
    <CloseIcon label={ariaLabel ?? label} />
  </Tooltip>
)

ClearIconTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipAlignment: PropTypes.oneOf(["start", "center", "end"]),
  tooltipPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
}

export default ClearIconTooltip
