import { forwardRef, useCallback, useMemo, useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { always, cond, isNil, T } from "ramda"
import moment from "moment"
import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"
import { noop } from "@ninjaone/utils"
import { Select, TableFilterButton, DatePickerPopover, Button } from "@ninjaone/components"

import { date, isNotNilOrEmpty } from "js/includes/common/utils"
import { localized } from "js/includes/common/utils/ssrAndWebUtils/"
import OutsideClickAlerter from "js/includes/components/OutsideClickAlerter"
import { Flex } from "js/includes/components/Styled"

const StyledButton = styled.button`
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  border-radius: ${tokens.borderRadius[1]};

  &:focus {
    outline-color: ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledFlex = styled(Flex)`
  border-color: ${({ theme }) => theme.colorBorderWeakest};
`

const ButtonWrapper = forwardRef(({ children, radixProps, onKeyDown }, forwardedRef) => (
  <StyledButton ref={forwardedRef} {...{ onKeyDown }} {...radixProps}>
    {children}
  </StyledButton>
))

const DateRangePickerSelectTableFilter = ({
  alwaysShowRemove,
  defaultValue,
  disabledDays,
  isRemovable,
  labelToken,
  mode,
  onDropdownClose = noop,
  onPickerClose,
  onRangeChange,
  onRemove,
  onSelect,
  openOnMount = false,
  options,
  value,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(openOnMount)
  const [range, setRange] = useState({})
  const buttonWrapperRef = useRef()
  const filters = useMemo(() => (isNotNilOrEmpty(value) ? [value] : []), [value])
  const formattedOptions = useMemo(() => options.map(({ label, value }) => ({ labelText: label, value })), [options])
  const locale = window.store?.getState().application.locale

  const formatFilters = useMemo(
    () =>
      cond([
        [isNil, always([])],
        [T, ({ from, to }) => (from ? [{ label: `${date(from)}${to ? `-${date(to)}` : ""}` }] : [])],
      ]),
    [],
  )

  const handleChange = useCallback(
    value => {
      const mode = value === "CUSTOM" ? "PICKER" : "SELECT"
      onSelect({ value, mode })
      setIsOpen(true)
    },
    [onSelect],
  )

  const handlePickerClose = useCallback(() => {
    onSelect(
      range?.from && range?.to
        ? { value: { from: range.from, to: range.to }, mode }
        : { value: "CUSTOM", mode: "SELECT" },
    )
    setIsOpen(false)
    buttonWrapperRef.current.focus()
  }, [onSelect, range, mode, setIsOpen, buttonWrapperRef])

  const handlePickerCancel = useCallback(() => {
    onSelect({ value: defaultValue?.id ?? null, mode: "SELECT" })
    setRange({})
    setIsOpen(false)
  }, [defaultValue, onSelect, setIsOpen, setRange])

  const openSelect = useCallback(() => {
    window.requestAnimationFrame(() =>
      buttonWrapperRef.current.dispatchEvent(new MouseEvent("pointerdown", { bubbles: true })),
    )
  }, [buttonWrapperRef])

  useEffect(() => {
    openOnMount && openSelect()
  }, [openOnMount, openSelect])

  useEffect(() => {
    mode === "SELECT" && !value && openSelect()
  }, [value, mode, openSelect])

  if (mode === "PICKER") {
    return (
      <DatePickerPopover
        alignPopover="start"
        disabledDays={disabledDays}
        onInteractOutside={handlePickerClose}
        footerRenderer={() => (
          <StyledFlex
            gap={tokens.spacing[2]}
            marginTop={tokens.spacing[3]}
            paddingTop={tokens.spacing[2]}
            justifyContent="flex-end"
            borderTop="1px solid"
            borderColor="colorBorderWeakest"
          >
            <Button type="secondary" onClick={handlePickerCancel}>
              {localized("Cancel")}
            </Button>
            <Button
              disabled={!range?.from || !range?.to}
              type="primary"
              onClick={() => {
                onRangeChange({
                  from: moment(range.from)
                    .startOf("day")
                    .toDate(),
                  to: moment(range.to)
                    .endOf("day")
                    .toDate(),
                })
                setIsOpen(false)
                buttonWrapperRef.current.focus()
              }}
            >
              {localized("Apply")}
            </Button>
          </StyledFlex>
        )}
        locale={locale}
        mode="range"
        onDayChange={({ range: { from, to } }) => {
          setRange(isNotNilOrEmpty(from) && isNotNilOrEmpty(to) ? { from, to } : {})
        }}
        onEscapeKeyDown={handlePickerClose}
        open={isOpen}
        selectedDays={value}
        useSameDayRange
        triggerRenderer={() => (
          <ButtonWrapper
            ref={buttonWrapperRef}
            onKeyDown={e => {
              if (e.key === "Enter") {
                setIsOpen(true)
              }
            }}
          >
            <TableFilterButton
              {...{
                hideTooltip: isOpen,
                handleClick: () => setIsOpen(isOpen => !isOpen),
                labelToken,
                filters: formatFilters(value),
                isRemovable,
                alwaysShowRemove: true,
                renderAsDiv: true,
                onRemove: () => {
                  isOpen && setIsOpen(false)
                  onRemove()
                },
                disabled,
              }}
            />
          </ButtonWrapper>
        )}
      />
    )
  }

  return (
    <OutsideClickAlerter handleClickOutside={onDropdownClose}>
      <Select
        {...{
          size: "sm",
          buttonRenderer: () => (
            <div ref={buttonWrapperRef}>
              <TableFilterButton
                {...{
                  filters,
                  onRemove,
                  labelToken,
                  isRemovable,
                  alwaysShowRemove,
                  renderAsDiv: true,
                  disabled,
                }}
              />
            </div>
          ),
          onChange: handleChange,
          options: formattedOptions,
          value: value?.value || "",
          popoverProps: {
            portal: false,
          },
          disabled,
        }}
      />
    </OutsideClickAlerter>
  )
}

DateRangePickerSelectTableFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  defaultValue: PropTypes.shape({
    from: PropTypes.object,
    to: PropTypes.object,
  }),
  value: PropTypes.shape({
    from: PropTypes.object,
    to: PropTypes.object,
  }),
  labelToken: PropTypes.string.isRequired,
  isRemovable: PropTypes.bool,
  alwaysShowRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  openOnMount: PropTypes.bool,
}

export default DateRangePickerSelectTableFilter
