import React, { memo } from "react"
import styled from "@emotion/styled"
import Select from "react-select"
import { faChevronDown, faMinus, faTimesCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import NoOptionsMessage from "js/includes/components/NoOptionsMessage"
import { is, isNil } from "ramda"

const StyledFilterOptionContainer = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  margin: 0px 15px 0px 5px;

  color: ${({ disabled }) => (disabled ? "lightgray" : "")};
`

const StyledSelectWrapper = styled.div`
  margin-bottom: -10px;
  position: absolute;
  width: 300px;
  z-index: 1000;
`
const StyledFilterButton = styled.button`
  background: none;
  border: none;
  padding: 4px 0px;
  margin: 0;
  &:focus-visible {
    outline: 5px auto #2684ff;
  }
`

function shouldShowDelete(filter) {
  if (is(String, filter) || is(Array, filter)) {
    return !!filter.length
  } else {
    return !isNil(filter)
  }
}

export default memo(
  ({
    type,
    closeMenuOnSelect = true,
    label,
    isMulti,
    value,
    options,
    renderer,
    getOptionValue,
    getOptionLabel,
    onFilterChange,
    activeFilter,
    setActiveFilter,
    defaultFilters,
    showDeleteIcon = true,
    filters,
  }) => {
    const isActiveFilter = activeFilter === type
    const shouldShowDeleteIcon = shouldShowDelete(filters[type]) && showDeleteIcon

    const dropDownArrows = isActiveFilter ? (
      <FontAwesomeIcon icon={faMinus} size="xs" />
    ) : (
      <FontAwesomeIcon icon={faChevronDown} size="xs" />
    )

    // TODO: Dynamic Token
    return (
      <StyledFilterOptionContainer disabled={activeFilter && !isActiveFilter}>
        <StyledFilterButton
          className="m-b-xs filter-header"
          type="button"
          onClick={() => setActiveFilter(isActiveFilter ? "" : type)}
        >
          {label ? (
            label()
          ) : (
            <span className="m-r-xs">
              {localized(`general.${type}`)}: &nbsp;
              {isMulti ? value.length : value.label}
            </span>
          )}
          {!shouldShowDeleteIcon && dropDownArrows}
        </StyledFilterButton>

        {shouldShowDeleteIcon && defaultFilters && (
          <StyledFilterButton
            type="button"
            onClick={e => {
              e.stopPropagation()
              onFilterChange({ [type]: defaultFilters[type] })
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} size="xs" />
          </StyledFilterButton>
        )}

        {isActiveFilter && (
          <StyledSelectWrapper>
            {renderer ? (
              renderer({
                type,
                label,
                isMulti,
                value,
                options,
                renderer,
                onFilterChange,
                activeFilter,
                setActiveFilter,
                closeMenuOnSelect,
              })
            ) : (
              <Select
                {...{
                  value,
                  options,
                  isMulti,
                  getOptionValue,
                  getOptionLabel,
                  closeMenuOnSelect: !isMulti,
                  placeholder: localized("Type to filter options..."),
                  components: {
                    NoOptionsMessage: () => <NoOptionsMessage messageToken={localizationKey("No options available")} />,
                  },
                  onChange: option => {
                    onFilterChange({
                      [type]: isMulti ? option || [] : option,
                    })

                    !isMulti && setActiveFilter("")
                  },
                  onBlur: () => setActiveFilter(""),
                }}
                autoFocus
                openMenuOnFocus
              />
            )}
          </StyledSelectWrapper>
        )}
      </StyledFilterOptionContainer>
    )
  },
)
