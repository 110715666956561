import PropTypes from "prop-types"
import { TableFilter, Text } from "@ninjaone/components"
import InputWithDropdown from "./InputWithDropdown"

const InputWithUnitDropdownTableFilter = ({
  onChange,
  options,
  value = {
    inputValue: "",
    selectedValue: null,
  },
  labelToken,
  alwaysShowRemove,
  openOnMount,
  isRemovable,
  onRemove,
  onDropdownClose,
  titleToken,
  inputValidators = [],
  dropdownLabelToken,
  selectedFormatter = ({ inputValue, selectedValue }) => `${inputValue} ${selectedValue}`,
  inputProps,
  disabled,
}) => {
  const isValidValue = value.inputValue && value.selectedValue

  return (
    <TableFilter
      {...{
        labelToken,
        filters: isValidValue ? [{ ...value, label: selectedFormatter(value) }] : [],
        isRemovable,
        onRemove,
        onDropdownClose,
        alwaysShowRemove,
        openOnMount,
        containerProps: {
          maxWidth: "260px",
        },
        disabled,
      }}
    >
      <Text size="sm" token={titleToken} textWrap />
      <InputWithDropdown
        inputProps={inputProps}
        value={value}
        onChange={onChange}
        inputValidators={inputValidators}
        labelToken={dropdownLabelToken ?? labelToken}
        options={options}
      />
    </TableFilter>
  )
}

InputWithUnitDropdownTableFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  value: PropTypes.shape({
    inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedValue: PropTypes.string,
  }),
  labelToken: PropTypes.string.isRequired,
  alwaysShowRemove: PropTypes.bool,
  isRemovable: PropTypes.bool,
  onRemove: PropTypes.func,
  titleToken: PropTypes.string,
  inputValidators: PropTypes.array,
  dropdownLabelToken: PropTypes.string.isRequired,
  selectedFormatter: PropTypes.func,
  inputProps: PropTypes.object,
  onDropdownClose: PropTypes.func,
}

export default InputWithUnitDropdownTableFilter
