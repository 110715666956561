import { useState } from "react"
import PropTypes from "prop-types"
import { always, cond, isNil, T } from "ramda"
import moment from "moment"

import { date, isNotNilOrEmpty } from "js/includes/common/utils"
import OutsideClickAlerter from "js/includes/components/OutsideClickAlerter"
import { TableFilterButton, DatePickerPopover } from "@ninjaone/components"

const DateRangePickerTableFilter = ({
  onSelect,
  value,
  labelToken,
  isRemovable,
  alwaysShowRemove,
  onRemove,
  openOnMount = false,
  disabledDays,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(openOnMount)

  const formatFilters = cond([
    [isNil, always([])],
    [T, ({ from, to }) => (from ? [{ label: `${date(from)}${to ? `-${date(to)}` : ""}` }] : [])],
  ])

  const locale = window.store.getState().application.locale

  const RadixWrapper = ({ children, radixProps }) => <div {...radixProps}>{children}</div>

  return (
    <OutsideClickAlerter handleClickOutside={() => setIsOpen(false)}>
      <DatePickerPopover
        onEscapeKeyDown={() => setIsOpen(false)}
        open={isOpen}
        onDayChange={({ range: { from, to } }) => {
          if (isNotNilOrEmpty(from) && isNotNilOrEmpty(to)) {
            onSelect({
              from: moment(from)
                .startOf("day")
                .toDate(),
              to: moment(to)
                .endOf("day")
                .toDate(),
            })
          }
        }}
        selectedDays={value}
        locale={locale}
        alignPopover="start"
        mode="range"
        triggerRenderer={() => (
          <RadixWrapper>
            <TableFilterButton
              {...{
                hideTooltip: isOpen,
                handleClick: () => setIsOpen(isOpen => !isOpen),
                labelToken,
                filters: formatFilters(value),
                isRemovable,
                alwaysShowRemove,
                onRemove: () => {
                  onRemove?.()
                  isOpen && setIsOpen(false)
                },
                disabled,
              }}
            />
          </RadixWrapper>
        )}
        disabledDays={disabledDays}
      />
    </OutsideClickAlerter>
  )
}

DateRangePickerTableFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.shape({
    from: PropTypes.object,
    to: PropTypes.object,
  }),
  labelToken: PropTypes.string.isRequired,
  isRemovable: PropTypes.bool,
  alwaysShowRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  openOnMount: PropTypes.bool,
}

export default DateRangePickerTableFilter
