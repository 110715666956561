import { bodyOpenClassName } from "./constants"

export const getSelectContentMaxHeight = ({ optionsLength, listboxMaxHeight }) => {
  if (listboxMaxHeight) {
    return `${listboxMaxHeight}px`
  }

  return optionsLength > 8 ? "298px" : "unset"
}

export const getSelectContentMaxWidth = ({ buttonRenderer, listboxMinWidth }) => {
  if (buttonRenderer) {
    return `250px`
  }

  return listboxMinWidth || `var(--radix-select-trigger-width)`
}

export const getSelectContentMinWidth = ({ listboxMinWidth }) => {
  if (listboxMinWidth) {
    return `${listboxMinWidth}px`
  }

  return "140px"
}

export const getSelectMinWidth = ({ size, triggerMinWidth }) => {
  if (triggerMinWidth) {
    return `${triggerMinWidth}px`
  }

  if (size === "sm") {
    return "160px"
  }

  return "298px"
}

// This is implemented because we can't disable the modal functionality for the Select component.
// Check: https://github.com/radix-ui/primitives/issues/1496#issuecomment-1172107676
export const handleChangeModalClass = isOpenState => {
  const body = document.body

  if (isOpenState) {
    body.classList.add(bodyOpenClassName)
  } else {
    body.classList.remove(bodyOpenClassName)
  }
}
