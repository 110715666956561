import PropTypes from "prop-types"
import FocusLock from "react-focus-lock"

const FocusTrap = ({
  children,
  autoFocus = true,
  onDeactivation,
  disabled,
  shouldIgnoreActiveElement,
  returnFocus,
}) => {
  return (
    <FocusLock
      {...{
        autoFocus,
        onDeactivation,
        disabled,
        returnFocus,
        ...(shouldIgnoreActiveElement && {
          whiteList: activeElement => {
            const isFocusGuard = !!activeElement.getAttribute("data-focus-guard")

            return isFocusGuard || shouldIgnoreActiveElement(activeElement)
          },
        }),
      }}
    >
      {children}
    </FocusLock>
  )
}

export default FocusTrap

FocusTrap.propTypes = {
  children: PropTypes.node.isRequired,
  autoFocus: PropTypes.bool,
  onDeactivation: PropTypes.func,
  disabled: PropTypes.bool,
  shouldIgnoreActiveElement: PropTypes.func,
  returnFocus: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}
