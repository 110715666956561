import DebouncedInput from "./DebouncedInput"
import WithDropdown from "./WithDropdown"

export const radioType = {
  DEFAULT: "default",
  WITH_INPUT: "withInput",
  WITH_DROPDOWN: "withDropdown",
}

export const radioComponents = {
  [radioType.DEFAULT]: null,
  [radioType.WITH_INPUT]: DebouncedInput,
  [radioType.WITH_DROPDOWN]: WithDropdown,
}

export const hasAdditionalComponent = type => ![undefined, radioType.DEFAULT].includes(type)

export const isValidRadioValue = (radioValue, radioType, additionalValue) => {
  const _hasAdditionalComponent = hasAdditionalComponent(radioType)
  return radioValue && (!_hasAdditionalComponent || (_hasAdditionalComponent && additionalValue !== ""))
}
