import { Fragment } from "react"
import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { useMountedState } from "js/includes/common/hooks"
import { Flex } from "js/includes/components/Styled"
import Radio from "./Radio"
import { hasAdditionalComponent, isValidRadioValue, radioComponents } from "./utils"

const AdditionalComponentWrapper = styled(Flex)`
  margin-top: ${sizer(1)};
  margin-left: ${sizer(6)};
`

const RadioGroup = ({ name, options, value, onChange }) => {
  const [selectedRadioValue, setSelectedRadioValue] = useMountedState(value.radioValue)

  const handleOnChange = (radioValue, radioType, additionalValue) => {
    if (isValidRadioValue(radioValue, radioType, additionalValue)) {
      onChange?.({
        radioValue,
        radioType,
        additionalValue,
      })
    }
  }

  return (
    <Flex flexDirection="column">
      {options.map(({ value: optionValue, name, labelToken, type, additionalComponentProps }) => {
        const isSelected = selectedRadioValue === optionValue
        const Component = radioComponents[type]
        return (
          <Fragment key={optionValue}>
            <Radio
              {...{
                value: optionValue,
                name,
                checked: isSelected,
                onChange: e => {
                  setSelectedRadioValue(e.target.value)
                  handleOnChange(
                    optionValue,
                    type,
                    hasAdditionalComponent(type) ? additionalComponentProps?.defaultValue ?? "" : "",
                  )
                },
                labelToken,
              }}
            />
            {Component && isSelected && (
              <AdditionalComponentWrapper>
                <Component
                  {...{
                    onChange: componentValue => handleOnChange(optionValue, type, componentValue),
                    value: value.additionalValue,
                    ...additionalComponentProps,
                  }}
                />
              </AdditionalComponentWrapper>
            )}
          </Fragment>
        )
      })}
    </Flex>
  )
}

export default RadioGroup
