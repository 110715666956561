import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { Body } from "@ninjaone/components"

export const StyledTooltipContent = styled.div`
  position: relative;
  padding: ${tokens.spacing[1]};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};
  width: ${({ width }) => width || "110px"};
  box-shadow: ${({ theme }) => theme.elevationStrong};
`

export const StyledTooltipContentHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  margin-bottom: ${tokens.spacing[1]};
  padding-left: ${tokens.spacing[2]};
`

const SimpleChartTooltip = ({ title, value, valueSecondary }) => {
  return (
    <StyledTooltipContent>
      <StyledTooltipContentHeader>
        <Body marginBottom={tokens.spacing[1]} type="bodyXs">
          {title}
        </Body>
      </StyledTooltipContentHeader>
      <Box paddingLeft={tokens.spacing[2]}>
        <output aria-live="assertive">
          {!!value && (
            <Body color="colorTextStrong" fontWeight={tokens.typography.fontWeight.medium} type="headingS">
              {value}
            </Body>
          )}
          {!!valueSecondary && (
            <Body color="colorTextWeakest" type="bodyXs">
              {valueSecondary}
            </Body>
          )}
        </output>
      </Box>
    </StyledTooltipContent>
  )
}

export default SimpleChartTooltip
