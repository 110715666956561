import { always, cond, identity, isNil } from "ramda"
import PropTypes from "prop-types"
import { TableFilter } from "@ninjaone/components"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const SearchableSelectTableFilter = ({
  onSelect,
  options,
  value,
  labelToken,
  isRemovable,
  alwaysShowRemove,
  onRemove,
  isMulti = true,
  searchableProps,
  openOnMount,
  onDropdownClose,
  labelSelectorKey,
  onDropdownOpen,
  disabled,
}) => {
  const formatFilters = cond([
    [isNil, always([])],
    [() => Array.isArray(value), always(value)],
    [identity, always([value])],
  ])

  return (
    <TableFilter
      {...{
        labelToken,
        filters: formatFilters(value),
        isRemovable,
        alwaysShowRemove,
        onRemove,
        useContainer: false,
        openOnMount,
        onDropdownClose,
        labelSelectorKey,
        onDropdownOpen,
        disabled,
      }}
    >
      {({ setIsOpen }) => (
        <SearchableDropDown
          {...{
            onSelect: val => {
              onSelect(val)
              !isMulti && setIsOpen(false)
            },
            options,
            value,
            isMulti,
            autoFocus: true,
            openOnFocus: true,
            keepInView: false,
            useFilterStyling: true,
            disabled,
            ...searchableProps,
          }}
        />
      )}
    </TableFilter>
  )
}

SearchableSelectTableFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  labelToken: PropTypes.string.isRequired,
  isRemovable: PropTypes.bool,
  alwaysShowRemove: PropTypes.bool,
  openOnMount: PropTypes.bool,
  onRemove: PropTypes.func,
  onDropdownClose: PropTypes.func,
  isMulti: PropTypes.bool,
  /**
   * The props that are going to be passed down to SearchableDropDown component
   */
  searchableProps: PropTypes.object,
}

export default SearchableSelectTableFilter
