import PropTypes from "prop-types"
import styled from "@emotion/styled"

import { keyframes } from "@emotion/react"

const skeletonKeyframes = keyframes`
  0% {
    background-position: -1800px 0;
  }
  100% {
    background-position: calc(1800px + 100%) 0;
  }
`

const StyledSkeleton = styled.div`
  display: ${props => props.display || "inline-block"};
  height: ${props => props.height || "21px"};

  width: ${props => props.width || "80%"};
  min-width: ${props => props.width || "80%"};

  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;

  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => theme.colorBackgroundAccentNeutralWeak} 30%,
    ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest} 60%,
    ${({ theme }) => theme.colorBackgroundAccentNeutralWeak} 90%
  );

  background-size: 1800px 100%;
  background-repeat: no-repeat;

  border-radius: 2px;

  margin-top: ${props => props.marginTop || "0"};
  margin-right: ${props => props.marginRight || "0"};
  margin-bottom: ${props => props.marginBottom || "0"};
  margin-left: ${props => props.marginLeft || "0"};
`

const StyledColumn = styled.div`
  display: flex;
  gap: ${props => props.spacing?.column || "0"};
`

const StyledRow = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: ${props => props.spacing?.row || "0"};
`

function Skeleton(props) {
  const { areas, items: columns, spacing, ...rest } = props

  if (!columns) {
    return <StyledSkeleton {...rest} />
  }

  return (
    <StyledColumn {...{ spacing }}>
      {columns.map((column, columnIndex) => {
        const hasRows = Array.isArray(column)
        if (!hasRows) return <StyledSkeleton {...{ key: `${JSON.stringify(column)}-${columnIndex}`, ...column }} />

        return (
          <StyledRow {...{ spacing, key: `${JSON.stringify(column)}-${columnIndex}` }}>
            {column.map((row, rowIndex) => (
              <StyledSkeleton {...{ key: `${JSON.stringify(row)}-${rowIndex}`, ...row }} />
            ))}
          </StyledRow>
        )
      })}
    </StyledColumn>
  )
}

export const skeletonElementProps = {
  /**
   * The margin bottom of the component.
   */
  marginBottom: PropTypes.string,
  /**
   * The margin top of the component.
   */
  marginTop: PropTypes.string,
  /**
   * The margin left of the component.
   */
  marginLeft: PropTypes.string,
  /**
   * The margin right of the component.
   */
  marginRight: PropTypes.string,
  /**
   *  The display property of the component.
   */
  display: PropTypes.string,
  /**
   * The height of the component.
   */
  height: PropTypes.string,
  /**
   * The width of the component.
   */
  width: PropTypes.string,
}

export const skeletonProps = {
  /**
   * Creates a custom shape for the Skeleton component.
   * If provided, it will override the default Skeleton.
   * Usage examples:
   * - [{}] - 1 column with one element.
   * - [[{}], {}], 1 column with 1 row, 1 column with one element.
   * - [[{}, {}], [{}, {}]] - 2 columns with 2 rows.
   */
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(skeletonElementProps)),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape(skeletonElementProps))),
  ]),
  /**
   * The default spacing between skeleton components when using the custom shape.
   */
  spacing: PropTypes.shape({
    row: PropTypes.string,
    column: PropTypes.string,
  }),
}

Skeleton.propTypes = {
  ...skeletonElementProps,
  ...skeletonProps,
}

export default Skeleton
