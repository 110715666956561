import { InfiniteLoader } from "react-virtualized"
import { memo, useEffect } from "react"
import { noRowsRenderer } from "js/includes/common/utils"
import { useMounted, useMountedState } from "js/includes/common/hooks"
import Table from "js/includes/components/Table"

export default memo(
  ({
    itemsToRender,
    shouldContinueLoading,
    disablePagination,
    search,
    itemsLoaded,
    setItemsLoaded,
    columns,
    rowHeight,
    internalRowRenderer,
    noRowsRendererToken,
    loading,
    fetching,
    focusedRow,
  }) => {
    const [infiniteLoaderScrollIndex, setInfiniteLoaderScrollIndex] = useMountedState(0)
    const mounted = useMounted()

    useEffect(() => {
      focusedRow !== null && setInfiniteLoaderScrollIndex(focusedRow)
    }, [focusedRow, setInfiniteLoaderScrollIndex])

    useEffect(() => {
      setInfiniteLoaderScrollIndex(itemsToRender.length)
    }, [itemsToRender, setInfiniteLoaderScrollIndex])

    const handleLoadMore = async () => {
      if (!shouldContinueLoading || disablePagination) return
      const items = await search()
      mounted.current && setItemsLoaded([...itemsLoaded, ...items])
    }

    return (
      <InfiniteLoader
        {...{
          rowCount: itemsToRender.length,
          loadMoreRows: handleLoadMore,
          isRowLoaded: ({ index }) => index < itemsToRender.length - 1,
        }}
      >
        {({ onRowsRendered }) => (
          <Table
            {...{
              columns,
              rowHeight,
              onRowsRendered,
              disableHeader: true,
              rowRenderer: internalRowRenderer,
              list: itemsToRender,
              scrollToIndex: infiniteLoaderScrollIndex,
              noRowsRenderer: noRowsRenderer(noRowsRendererToken, fetching || loading, "text-align-left m-l-sm"),
            }}
          />
        )}
      </InfiniteLoader>
    )
  },
)
