import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { Body } from "@ninjaone/components"
import { StyledTooltipContent, StyledTooltipContentHeader } from "./SimpleChartTooltip"

const StyledStatusBar = styled.div`
  width: 5px;
  height: 23px;
`

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${tokens.spacing[2]};

  &:not(:first-of-type) {
    padding-top: ${tokens.spacing[1]};
  }

  &:not(:last-child) {
    padding-bottom: ${tokens.spacing[1]};
    border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeak};
  }
`

const ComplexChartTooltip = ({ title, rows }) => {
  return (
    <StyledTooltipContent width="170px">
      <StyledTooltipContentHeader>
        <Body marginBottom={tokens.spacing[1]} type="bodyXs">
          {title}
        </Body>
      </StyledTooltipContentHeader>
      <Box paddingLeft={tokens.spacing[2]}>
        <output aria-live="assertive">
          {rows.map(({ name, value, statusBarColor }, index) => {
            return (
              <StyledRow key={name}>
                <Flex alignItems="center" gap={tokens.spacing[2]}>
                  {statusBarColor && <StyledStatusBar style={{ backgroundColor: statusBarColor }} />}
                  {!!name && (
                    <Body color="colorTextWeakest" type="bodyXs">
                      {name}
                    </Body>
                  )}
                </Flex>
                {!!value && (
                  <Box marginRight={tokens.spacing[2]}>
                    <Body color="colorTextWeakest" type="bodyXs">
                      {value}
                    </Body>
                  </Box>
                )}
              </StyledRow>
            )
          })}
        </output>
      </Box>
    </StyledTooltipContent>
  )
}

export default ComplexChartTooltip
