import React, { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { indexOf } from "ramda"

import tokens from "@ninjaone/tokens"

import { useMountedState } from "js/includes/common/hooks"
import {
  isDownKey,
  isEndKey,
  isEnterKey,
  isHomeKey,
  isPageDownKey,
  isPageUpKey,
  isUpKey,
} from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import Portal from "js/includes/components/Portal"
import { Box } from "js/includes/components/Styled"
import { layout, position } from "js/includes/components/Styled/system"

import InfiniteDropdown from "./InfiniteDropdown"
import LocalOptionsList from "./LocalOptionsList"

const StyledDropDown = styled(Box)`
  ${({ useFilterStyling, theme }) =>
    !useFilterStyling &&
    `box-shadow: ${theme.elevationWeak};
     border: 1px solid ${theme.colorBorderWeak};
     border-radius: ${tokens.borderRadius[1]};`}
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  overflow: hidden;
  width: 100%;
  z-index: 100000;
  max-height: 300px;
  ${position}
  ${layout}
  ${({ useSelectStyling, theme }) =>
    useSelectStyling &&
    `color: ${theme.colorTextStrong};
    font-size: ${tokens.typography.fontSize.body};`}
`

const StyledDropDownChoices = styled(Box)`
  display: flex;
  flex: 1;
  height: ${({ height }) => (height ? height + "px" : "auto")};
  min-height: ${({ rowHeight }) => rowHeight}px;
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.colorTextStrong};

  .virtualized-table .table-row:not(.ReactVirtualized__Table__headerRow) {
    &:not(:last-child) {
      border-bottom: none;
    }
    &.checked {
      background-color: ${({ theme }) =>
        theme.colorForegroundSelected} !important; // override color from react-virtualized.less
    }
  }

  .horizontal-tiles .horizontal-tile.flex-tile > div {
    min-height: ${({ rowHeight }) => rowHeight}px;
  }

  .local-options-row:hover,
  .virtualized-table .table-row:not(.ReactVirtualized__Table__headerRow):hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};

    :not(.checked) {
      color: ${({ theme }) => theme.colorTextStrong};
    }
  }
  .focused-row {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }
`

const StyledRow = styled(Box)`
  padding-left: 5px;
  width: 100%;
  text-align: left;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.colorForegroundSelected : "transparent")};
`

const getAllOptions = itemsToRender => {
  return itemsToRender.map(item => (item.options ? getAllOptions(item.options) : item)).flat()
}

const Dropdown = memo(
  forwardRef(
    (
      {
        id,
        className,
        open,
        selected,
        value,
        inputValue,
        fetching,
        loading,
        isLocalList,
        isCreatable,
        rowHeight,
        isMulti,
        rowRenderer,
        valueSelectorKey,
        itemsToRender,
        handleRowClick,
        choicesHeight,
        shouldContinueLoading,
        disablePagination,
        pageSize,
        itemsLoaded,
        search,
        setItemsLoaded,
        parentRef,
        keepInView,
        dropdownContainerId,
        dropdownContainerRef,
        optionsWrap,
        handleDeleteSingleSelection,
        noRowsRendererToken: _noRowsRendererToken,
        useFilterStyling,
        useSelectStyling,
        setFocusedIndexRowDropdown,
        noRowsCreatableToken,
      },
      elementRef,
    ) => {
      const dropdownRef = useRef(null)
      const rowRef = useRef({})
      const yRef = useRef(null)

      const [shouldRenderOnTop, setShouldRenderOnTop] = useState(false)
      const [focusedRow, setFocusedRow] = useMountedState(null)
      const [parentBounds, setParentBounds] = useMountedState(parentRef?.getBoundingClientRect() ?? {})

      const windowHeight = window.innerHeight || document.documentElement.clientHeight
      const noRowsRendererToken =
        isCreatable && inputValue?.length
          ? noRowsCreatableToken || localizationKey("No results match. Press Enter to create.")
          : _noRowsRendererToken

      const itemsOptions = useMemo(() => (isLocalList ? getAllOptions(itemsToRender) : itemsToRender), [
        itemsToRender,
        isLocalList,
      ])

      const calculatePosition = () => {
        const updatedWindowHeight = window.innerHeight || document.documentElement.clientHeight

        if (!yRef.current) {
          yRef.current = updatedWindowHeight - window.event?.clientY
        }

        const dropdownBounds = dropdownRef.current?.getBoundingClientRect() ?? {}
        const _shouldRenderOnTop =
          updatedWindowHeight < dropdownBounds?.bottom ||
          yRef.current <= dropdownBounds?.height ||
          // 1. validates if the vertical coordinate of the event (click) is less or equal to the bottom of the dropdown
          // 2. checks if event coordinate is under 400px from the bottom of the window
          (yRef.current <= dropdownBounds?.bottom && yRef.current < 400)

        setShouldRenderOnTop(_shouldRenderOnTop)
      }

      useEffect(() => {
        window.addEventListener("resize", calculatePosition)
        return () => window.removeEventListener("resize", calculatePosition)
      }, [])

      const autoScrollIntoView = useCallback(
        rowData => {
          const rowIndex = indexOf(rowData, itemsOptions)
          rowRef &&
            rowRef.current[rowIndex]?.scrollIntoView?.({ behavior: "smooth", block: "nearest", inline: "nearest" })
        },
        [rowRef, itemsOptions],
      )

      useEffect(() => {
        const onKeyDownSelect = e => {
          if (isDownKey(e)) {
            if (focusedRow === null) {
              setFocusedRow(0)
            } else if (focusedRow < itemsOptions.length - 1) {
              setFocusedRow(focusedRow => focusedRow + 1)
            }
          }

          if (isUpKey(e) && focusedRow > 0) {
            setFocusedRow(focusedRow => focusedRow - 1)
          }

          if (isEnterKey(e) && focusedRow !== null) {
            handleRowClick(itemsOptions[focusedRow])

            setFocusedIndexRowDropdown && setFocusedRow(null)
          }

          if (isEndKey(e) || isPageDownKey(e)) {
            setFocusedRow(itemsOptions.length - 1)
          }

          if (isHomeKey(e) || isPageUpKey(e)) {
            setFocusedRow(0)
          }
        }

        window.addEventListener("keydown", onKeyDownSelect)
        return () => window.removeEventListener("keydown", onKeyDownSelect)
      }, [focusedRow, setFocusedRow, handleRowClick, itemsOptions, setFocusedIndexRowDropdown])

      useEffect(() => {
        if (isLocalList && focusedRow !== null && focusedRow < itemsOptions.length) {
          autoScrollIntoView(itemsOptions[focusedRow])
        }

        setFocusedIndexRowDropdown?.(focusedRow)
      }, [focusedRow, autoScrollIntoView, itemsOptions, isLocalList, setFocusedIndexRowDropdown])

      useEffect(() => {
        if (!fetching && !!dropdownRef.current && keepInView) {
          calculatePosition()
        }
      }, [dropdownRef, fetching, keepInView])

      /*
       * Effect to update the position of the portal (that contains the options) when the dropdown size changes.
       *
       * This is only needed when keepInView is true, since in that case Portal is used.
       */
      useEffect(() => {
        if (!keepInView || !parentRef) {
          return
        }

        const observer = new ResizeObserver(() => {
          setParentBounds(parentRef.getBoundingClientRect() ?? {})
        })

        observer.observe(parentRef)

        return () => {
          observer.disconnect()
        }
      }, [keepInView, parentRef, setParentBounds])

      const isFocused = row => focusedRow === indexOf(row, itemsOptions)

      const internalRowRenderer = ({ className, columns, index, key, rowData, style }) => {
        const isRowSelected = isMulti
          ? selected.some(s => s[valueSelectorKey] === rowData[valueSelectorKey])
          : selected?.[valueSelectorKey] === rowData?.[valueSelectorKey]
        const isFocusedRow = isFocused(rowData)
        const shouldRowBeHighlighted = isFocusedRow || isRowSelected
        const highlightClassName = isFocusedRow ? "focused-row" : "checked"
        const optionProps = {
          key,
          style,
          role: "option",
          tabIndex: 0,
          "aria-rowindex": index + 1,
          className: `${className} ${shouldRowBeHighlighted ? highlightClassName : ""} ${
            rowData.disabled ? "no-hover" : ""
          }`,
          onClick: () => {
            handleRowClick(rowData)
          },
        }

        return <div {...optionProps}>{columns}</div>
      }

      const columns = [
        {
          dataKey: "name",
          width: 1,
          flexGrow: 1,
          className: "no-margin-left",
          cellRenderer: ({ rowData }) => (
            <StyledRow ref={r => (rowRef.current[indexOf(rowData, itemsOptions)] = r)}>
              {rowRenderer(rowData, inputValue)}
            </StyledRow>
          ),
        },
      ]
      const dropDownProps = {
        id,
        className,
        role: "listbox",
        ref: ref => {
          dropdownRef.current = ref
          elementRef(ref)
        },
        useSelectStyling,
        useFilterStyling,
        ...(useFilterStyling
          ? {}
          : keepInView
          ? {
              position: "fixed",
              left: parentBounds.left,
              right: parentBounds.right,
              width: parentBounds.width,
              ...(shouldRenderOnTop
                ? { bottom: windowHeight - parentBounds.top + 8 }
                : { top: parentBounds.bottom + 8 }),
            }
          : {
              position: "absolute",
              marginTop: "8px",
              marginBottom: "8px",
              top: "100%",
            }),
      }
      const dropdown = (
        <StyledDropDown {...dropDownProps} data-testid="styled-dropdown">
          {isLocalList ? (
            <StyledDropDownChoices {...{ rowHeight, isMulti, useFilterStyling }}>
              <LocalOptionsList
                {...{
                  loading,
                  isMulti,
                  selected,
                  value,
                  rowHeight,
                  valueSelectorKey,
                  noRowsRendererToken,
                  options: itemsToRender,
                  onRowClick: handleRowClick,
                  isRowFocused: isFocused,
                  rowRenderer: rowData => (
                    <StyledRow
                      ref={r => (rowRef.current[indexOf(rowData, itemsOptions)] = r)}
                      data-testid="dropdown-option"
                    >
                      {rowRenderer(rowData, inputValue)}
                    </StyledRow>
                  ),
                  optionsWrap,
                }}
                data-testid="dropdown-options"
              />
            </StyledDropDownChoices>
          ) : (
            <StyledDropDownChoices {...{ height: choicesHeight, rowHeight, isMulti, useFilterStyling }}>
              <InfiniteDropdown
                {...{
                  itemsToRender,
                  shouldContinueLoading,
                  disablePagination,
                  search,
                  itemsLoaded,
                  setItemsLoaded,
                  columns,
                  rowHeight,
                  internalRowRenderer,
                  noRowsRendererToken,
                  loading,
                  fetching,
                  focusedRow,
                }}
              />
            </StyledDropDownChoices>
          )}
        </StyledDropDown>
      )

      return keepInView ? (
        <Portal containerId={dropdownContainerId} containerRef={dropdownContainerRef}>
          {dropdown}
        </Portal>
      ) : (
        dropdown
      )
    },
  ),
)

Dropdown.propTypes = {
  itemsToRender: PropTypes.array.isRequired,
}

export default Dropdown
