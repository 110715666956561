import { useMemo, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Select, TableFilterButton } from "@ninjaone/components"
import { noop } from "@ninjaone/utils"
import { isNotNil, localized } from "js/includes/common/utils/ssrAndWebUtils"
import OutsideClickAlerter from "./OutsideClickAlerter"

const SingleSelectTableFilter = ({
  value: filterValue,
  isRemovable,
  options,
  onSelect,
  labelToken,
  alwaysShowRemove,
  openOnMount,
  onRemove,
  onDropdownClose = noop,
  disabled,
  onDropdownOpen = noop,
}) => {
  const buttonWrapperRef = useRef()
  const filters = useMemo(() => (isNotNil(filterValue) ? [filterValue] : []), [filterValue])
  const formattedOptions = useMemo(() => options.map(({ label, value }) => ({ labelText: label, value })), [options])

  useEffect(() => {
    if (openOnMount) {
      window.requestAnimationFrame(() =>
        buttonWrapperRef.current.dispatchEvent(new MouseEvent("pointerdown", { bubbles: true })),
      )
    }
  }, [openOnMount])

  return (
    <OutsideClickAlerter handleClickOutside={onDropdownClose}>
      <Select
        {...{
          size: "sm",
          buttonRenderer: () => (
            <div ref={buttonWrapperRef}>
              <TableFilterButton
                {...{
                  filters,
                  onRemove,
                  labelToken,
                  isRemovable,
                  alwaysShowRemove,
                  renderAsDiv: true,
                  disabled,
                }}
              />
            </div>
          ),
          onChange: onSelect,
          options: formattedOptions,
          value: filterValue?.value || "",
          popoverProps: {
            portal: false,
          },
          disabled,
          onOpenChange: openState => {
            openState && onDropdownOpen()
          },
          triggerAriaLabel: labelToken && localized(labelToken),
        }}
      />
    </OutsideClickAlerter>
  )
}

SingleSelectTableFilter.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  isRemovable: PropTypes.bool,
  onSelect: PropTypes.func,
  labelToken: PropTypes.string,
  alwaysShowRemove: PropTypes.bool,
  openOnMount: PropTypes.bool,
  onRemove: PropTypes.func,
  onDropdownClose: PropTypes.func,
  onDropdownOpen: PropTypes.func,
}

export default SingleSelectTableFilter
