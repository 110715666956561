import TabTriggerContent from "./TabTriggerContent"
import HoverDropdown from "../../HoverDropdown/HoverDropdown"
import HoverDropdownItem from "../../HoverDropdown/HoverDropdownItem"

const TabTrigger = ({
  count,
  options,
  tabIndex,
  labelText,
  labelToken,
  displayError,
  onOptionChange,
  selectedTabIndex,
  selectedOptionIndex,
  disabled: disabledTab,
}) => {
  if (options && options.length) {
    const dropdownOptions = () => (
      <ul>
        {options.map((currentOption, currentOptionIndex) => {
          const { action, disabled } = currentOption
          const active = selectedOptionIndex === currentOptionIndex && selectedTabIndex === tabIndex

          return (
            <HoverDropdownItem
              {...{
                active,
                disabled,
                cursorPointer: true,
                usesActiveClass: true,
                key: currentOptionIndex,
                plainText: currentOption.labelText,
                onClick: () => {
                  const callbackContent = { optionIndex: currentOptionIndex, tabIndex }

                  onOptionChange?.(callbackContent)
                  action?.(callbackContent)
                },
              }}
            />
          )
        })}
      </ul>
    )

    const triggerContent = () => (
      <TabTriggerContent
        {...{ labelToken, labelText, count, displayError, hasOptions: true, tabIndex, disabled: disabledTab }}
      />
    )

    return (
      <HoverDropdown
        {...{
          dropdownMargin: true,
          disabled: disabledTab,
          buttonRenderer: triggerContent,
          dropdownRenderer: dropdownOptions,
        }}
      />
    )
  }

  return <TabTriggerContent {...{ labelToken, labelText, count, displayError, tabIndex, disabled: disabledTab }} />
}

export default TabTrigger
