import colors from "./primitives.json"

const { red, green, blue, yellow, orange, neutral, ninjaBlue } = colors

const background = {
  colorBackground: neutral["0"],
  colorBackgroundWidget: neutral["0"],
  colorBackgroundSubtle: neutral["5"],
  colorBackgroundCta: blue["100"],
  colorBackgroundCtaWeak: blue["75"],
  colorBackgroundCtaDanger: red["100"],
  colorBackgroundCtaDisabled: neutral["50"],
  colorBackgroundInput: neutral["0"],
  colorBackgroundInputDisabled: neutral["10"],
  colorBackgroundAccentCtaWeak: blue["10"],
  colorBackgroundAccentCtaStrong: blue["100"],
  colorBackgroundAccentCtaStrongest: blue["150"],
  colorBackgroundAccentNeutralWeak: neutral["50"],
  colorBackgroundAccentNeutralWeakest: neutral["10"],
  colorBackgroundAccentNeutral: neutral["100"],
  colorBackgroundAccentNeutralDark: neutral["500"],
  colorBackgroundAccentNeutralDarker: neutral["600"],
  colorBackgroundAccentNeutralDarkest: neutral["800"],
  colorBackgroundProduct: ninjaBlue["500"],
}

const foreground = {
  colorForegroundHover: neutral["10"],
  colorForegroundHoverStrong: neutral["50"],
  colorForegroundCtaHover: blue["150"],
  colorForegroundCtaDangerHover: red["150"],
  colorForegroundSelected: blue["10"],
  colorForegroundSelectedStrong: blue["50"],
  colorForegroundSelectedStrongest: blue["100"],
  colorForegroundFocus: ninjaBlue["100"],
  colorForegroundFocusNeutral: neutral["0"],
  colorForegroundTextHighlight: yellow["100"],
  colorForegroundScrollbar: neutral["100"],
}

const border = {
  colorBorderStrong: neutral["300"],
  colorBorderWeak: neutral["100"],
  colorBorderWeakest: neutral["50"],
  colorBorderError: red["100"],
  colorBorderDecorativeStrong: blue["100"],
  colorBorderDecorativeWeak: blue["50"],
}

const text = {
  colorTextStrong: neutral["800"],
  colorTextWeak: neutral["600"],
  colorTextWeakest: neutral["500"],
  colorTextDisabled: neutral["300"],
  textDecorator: neutral["300"],
  colorTextHighContrast: neutral["0"],
  colorTextAction: blue["100"],
  colorTextActionStrong: blue["150"],
  colorTextDanger: red["100"],
  colorTextStrongInverse: neutral["0"],
  colorTextStrongStatic: neutral["800"],
}

const alert = {
  colorAlertSuccess: green["100"],
  colorAlertSuccessWeak: green["10"],
  colorAlertError: red["100"],
  colorAlertErrorWeak: red["10"],
  colorAlertWarningStrong: orange["100"],
  colorAlertWarning: yellow["100"],
  colorAlertWarningWeak: yellow["50"],
  colorAlertWarningWeakest: yellow["10"],
  colorAlertInfo: blue["100"],
  colorAlertInfoWeak: blue["10"],
  colorAlertNeutral: neutral["300"],
  colorAlertNeutralWeak: neutral["50"],
}

const dataViz = {
  colorDataViz1: "#0198AC", // TODO: this is still used in app so we need to to change instances to use new background token
  colorDataViz1Background: "#0198AC",
  colorDataViz2Background: "#9969ED",
  colorDataViz3Background: "#862D5D",
  colorDataViz4Background: "#5753F2",
  colorDataViz5Background: "#729D2D",
  colorDataViz6Background: "#00357E",
  colorDataViz7Background: "#D552D9",
  colorDataViz8Background: "#9A8CA1",
  colorDataViz9Background: "#005E73",
  colorDataViz10Background: "#A9710A",
  colorDataVizSuccessBackground: green["100"],
  colorDataVizSuccessHover: green["150"],
  colorDataVizErrorBackground: red["100"],
  colorDataVizErrorHover: red["150"],
  colorDataVizWarningBackground: yellow["100"],
  colorDataVizWarningHover: yellow["150"],
  colorDataVizNeutralBackground: neutral["300"],
  colorDataVizNeutralHover: neutral["500"],
}

const elevation = {
  elevationWeak: "0px 2px 8px 0px #0000001A",
  elevationStrong: "0px 4px 16px 0px #00000026",
  elevationStrongest: "0px 8px 48px 0px #00000026",
}

export const color = {
  ...background,
  ...foreground,
  ...border,
  ...text,
  ...alert,
  ...dataViz,
  ...elevation,
}
