import PropTypes from "prop-types"
import { TableFilter } from "@ninjaone/components"
import RadioGroup from "./RadioGroup"
import { radioType } from "./utils"

const RadioGroupTableFilter = ({
  onChange,
  options,
  name,
  value = {
    radioValue: "",
    radioType: radioType.DEFAULT,
    additionalValue: "",
  },
  labelToken,
  alwaysShowRemove,
  openOnMount,
  isRemovable,
  onRemove,
  onDropdownClose,
  selectedFormatter = ({ radioValue, additionalValue }) => `${radioValue} ${additionalValue ?? ""}`,
  disabled,
}) => {
  const isValidValue = value.radioValue !== ""

  return (
    <TableFilter
      {...{
        labelToken,
        filters: isValidValue ? [{ ...value, label: selectedFormatter(value) }] : [],
        isRemovable,
        onRemove,
        onDropdownClose,
        alwaysShowRemove,
        openOnMount,
        disabled,
      }}
    >
      <RadioGroup
        {...{
          name,
          options,
          value,
          onChange,
        }}
      />
    </TableFilter>
  )
}

RadioGroupTableFilter.propTypes = {
  value: PropTypes.shape({
    radioValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    radioType: PropTypes.string,
    additionalValue: PropTypes.string,
  }),
  options: PropTypes.array.isRequired,
  isRemovable: PropTypes.bool,
  onSelect: PropTypes.func,
  labelToken: PropTypes.string,
  alwaysShowRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  onDropdownClose: PropTypes.func,
}

export default RadioGroupTableFilter
