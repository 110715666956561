import { forwardRef } from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

import { isRequiredIf } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"

import InputActions from "../../Form/InputActions"

const StyledContainer = styled.div`
  position: relative;
  width: ${({ compact, fullWidth, width }) => (compact === false || fullWidth ? "100%" : `${width}px`)};

  #clearBtn {
    display: none;
  }

  &:hover #clearBtn,
  &:focus-within #clearBtn {
    display: ${({ disableClear }) => (disableClear ? "none" : "block")};
    opacity: 1;
  }
`

const StyledInput = styled.input`
  width: 100%;
  height: 38px;
  padding: ${({ isDateTime }) =>
    isDateTime
      ? `${tokens.spacing[2]} 0 ${tokens.spacing[2]} ${tokens.spacing[9]}`
      : `${tokens.spacing[2]} ${tokens.spacing[3]}`};
  border: ${({ error, disabled, isDateTime, theme }) =>
    isDateTime ? "0" : `1px solid ${error && !disabled ? theme.colorBorderError : theme.colorBorderWeak}`};
  border-radius: ${tokens.borderRadius[1]};
  color: ${({ disabled, theme }) => (disabled ? theme.colorTextDisabled : theme.colorTextStrong)};
  font-size: ${tokens.typography.fontSize.body};
  line-height: ${tokens.typography.lineHeight};
  background-color: ${({ disabled, theme }) => (disabled ? theme.colorBackgroundInputDisabled : theme.colorBackground)};
  cursor: ${({ readOnly }) => (readOnly ? "pointer" : "auto")};

  ::placeholder {
    color: ${({ error, disabled, theme }) => (error && !disabled ? theme.colorAlertError : theme.colorTextWeakest)};
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: ${({ isDateTime, theme }) => (isDateTime ? "0" : `2px solid ${theme.colorForegroundFocus}`)};
    outline-offset: 0;
  }
`

export const PickerInputWithActions = forwardRef(
  (
    {
      ariaLabel,
      ariaDescribedBy,
      ariaErrorId,
      compact,
      disableClear,
      disabled,
      error,
      fullWidth,
      iconIsTrigger,
      id,
      inputIcon,
      inputProps,
      isDateTime,
      maxLength,
      onBlur,
      onChange,
      onClearClick,
      onClick,
      onKeyDown,
      placeholder,
      readOnly,
      required,
      tooltipAlignment,
      tooltipPosition,
      value,
      width,
    },
    ref,
  ) => {
    const handleBlur = ({ relatedTarget }) => {
      if (relatedTarget && relatedTarget.dataset?.ninjaPickerOpen) {
        return
      }
      onBlur?.()
    }

    return (
      <StyledContainer {...{ compact, disableClear, fullWidth, width }}>
        {isDateTime && (
          <InputActions
            {...{
              dataAttribute: "picker-open",
              disabled,
              disableClear: true,
              iconIsTrigger,
              iconButton: inputIcon,
              isDateTime,
            }}
          />
        )}

        <StyledInput
          aria-describedby={ariaDescribedBy}
          aria-errormessage={`${ariaErrorId}-error`}
          aria-invalid={!!error && !disabled}
          aria-label={ariaLabel}
          aria-required={!!required && !disabled}
          onBlur={handleBlur}
          onChange={e => onChange?.(e.target.value)}
          type="text"
          {...{
            disabled,
            error,
            id,
            inputProps,
            isDateTime,
            maxLength,
            onClick,
            onKeyDown,
            placeholder,
            readOnly,
            ref,
            value,
          }}
        />

        {!isDateTime && (
          <InputActions
            {...{
              ariaErrorId,
              dataAttribute: "picker-open",
              disabled,
              disableClear,
              error,
              iconIsTrigger,
              iconButton: inputIcon,
              onClearClick,
              tooltipAlignment,
              tooltipPosition,
            }}
          />
        )}
      </StyledContainer>
    )
  },
)

PickerInputWithActions.propTypes = {
  ariaLabel: PropTypes.string,
  ariaDescribedBy: PropTypes.string.isRequired,
  ariaErrorId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disableClear: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inputIcon: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    onBlur: isRequiredIf(
      PropTypes.func,
      props => props.required === true,
      "`onBlur` is required when the required prop is true",
    ),
    icon: PropTypes.element.isRequired,
    ariaLabel: PropTypes.string.isRequired,
  }).isRequired,
  inputProps: PropTypes.object,
  maxLength: isRequiredIf(
    PropTypes.number,
    props => props.readOnly !== true,
    "`maxLength` is required when `readOnly` is false or undefined",
  ),
  onBlur: isRequiredIf(
    PropTypes.func,
    props => props.required === true,
    "`onBlur` is required when the required prop is true",
  ),
  onChange: PropTypes.func,
  onClearClick: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltipAlignment: PropTypes.oneOf(["start", "center", "end"]),
  tooltipPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
}
