import { useCallback, useMemo, useState } from "react"
import moment from "moment"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"
import DateRangePickerSelectTableFilter from "@ninjaone/webapp/src/js/includes/components/DateRangePickerSelectTableFilter"

const getSelectOptions = () => [
  { label: localized("Last day"), value: "LAST_DAY" },
  { label: localized("Last week"), value: "LAST_WEEK" },
  { label: localized("Last month"), value: "LAST_MONTH" },
  { label: localized("Last 3 months"), value: "LAST_THREE_MONTHS" },
  { label: localized("Custom range"), value: "CUSTOM" },
]

export const getDateRangeOptions = () => [
  {
    id: "LAST_THREE_MONTHS",
    label: localized("Last Three Months"),
    from: moment()
      .subtract(3, "months")
      .startOf("day")
      .toDate(),
    to: moment()
      .endOf("day")
      .toDate(),
  },
  {
    id: "LAST_DAY",
    label: localized("Last Day"),
    from: moment()
      .startOf("day")
      .toDate(),
    to: moment()
      .endOf("day")
      .toDate(),
  },
  {
    id: "LAST_WEEK",
    label: localized("Last Week"),
    from: moment()
      .subtract(1, "week")
      .startOf("day")
      .toDate(),
    to: moment()
      .endOf("day")
      .toDate(),
  },
  {
    id: "LAST_MONTH",
    label: localized("Last Month"),
    from: moment()
      .subtract(1, "months")
      .startOf("day")
      .toDate(),
    to: moment()
      .endOf("day")
      .toDate(),
  },
]

const DateRangePickerSelectFilter = ({
  filter: {
    name,
    value: filterValue,
    labelToken,
    defaultValue,
    shouldResetValue,
    onFilterChange,
    componentProps: { isRemovable = !defaultValue, disabledDays } = {},
  },
  updateFilter,
  alwaysShowRemove,
  onRemove,
  openOnMount = false,
  disabled,
}) => {
  const [mode, setMode] = useState("SELECT")
  const options = getSelectOptions()
  const selectedValue = useMemo(
    () => (mode === "PICKER" ? filterValue : getDateRangeOptions().find(({ id }) => id === filterValue?.id)),
    [filterValue, mode],
  )

  const onSelect = useCallback(
    ({ value, mode }) => {
      if (mode === "PICKER") {
        setMode(mode)
        return
      }

      const dateRangeOption = getDateRangeOptions().find(({ id }) => id === value)
      updateFilter(name, dateRangeOption)
      setMode("SELECT")
    },
    [updateFilter, name],
  )

  const onRangeChange = useCallback(
    ({ from, to }) => {
      updateFilter(name, {
        from,
        to,
      })
    },
    [updateFilter, name],
  )

  const handleRemove = () => {
    if (!isRemovable) {
      setMode("SELECT")
      updateFilter(name, defaultValue)
    } else {
      onRemove?.(name)
      updateFilter(name)
    }
  }

  return (
    <DateRangePickerSelectTableFilter
      {...{
        alwaysShowRemove,
        defaultValue,
        disabledDays,
        isRemovable,
        labelToken,
        mode,
        onRangeChange,
        onRemove: handleRemove,
        onSelect,
        openOnMount,
        options,
        value: selectedValue,
        disabled,
      }}
    />
  )
}

export default DateRangePickerSelectFilter
