import { memo, useState, useEffect, forwardRef } from "react"
import PropTypes from "prop-types"
import { AutoSizer, Table, Column } from "react-virtualized"
import { localized } from "js/includes/common/utils"
import { sortTableList } from "js/includes/common/utils/sort"
import { Box } from "js/includes/components/Styled"

const CustomizedTable = memo(
  forwardRef(
    (
      {
        id,
        list,
        columns,
        sort,
        sortBy,
        sortDirection,
        rowRenderer,
        noRowsRenderer,
        onSort,
        onSortedList,
        onScroll,
        onRowClick,
        onRowRightClick,
        onRowMouseOut,
        onRowMouseOver,
        rowHeight,
        headerHeight,
        className,
        rowClassName,
        gridClassName,
        headerClassName,
        containerClassName,
        onRowsRendered,
        disableHeader,
        onHeaderClick,
        scrollToIndex,
      },
      ref,
    ) => {
      const [sortedList, setSortedList] = useState(list)

      useEffect(() => {
        const sortedList = sort || sortBy || sortDirection ? onSort(list, sortBy, sortDirection, columns) : list
        setSortedList(sortedList)
        onSortedList && onSortedList(sortedList)
      }, [list, sort, sortBy, columns, sortDirection, onSort, onSortedList])

      return (
        <Box flex={1} className={containerClassName}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                {...{
                  id,
                  width,
                  height,
                  sort,
                  sortBy,
                  sortDirection,
                  rowRenderer,
                  noRowsRenderer,
                  onRowClick,
                  onRowRightClick,
                  onRowMouseOut,
                  onRowMouseOver,
                  rowHeight,
                  headerHeight,
                  className,
                  rowClassName,
                  gridClassName,
                  headerClassName,
                  onRowsRendered,
                  disableHeader,
                  onHeaderClick,
                  scrollToIndex,
                  ref,
                  rowCount: sortedList.length || 0,
                  rowGetter: ({ index }) => sortedList[index],
                  onScroll: info => {
                    const { clientHeight, scrollHeight, scrollTop } = info
                    const height = scrollTop > 0 ? scrollHeight - scrollTop : 0
                    const bottom = height === clientHeight
                    onScroll && onScroll({ height, bottom, info })
                  },
                }}
              >
                {columns?.map(({ dataKey, className, label = () => {}, ...column }) => {
                  return (
                    <Column
                      {...{
                        dataKey,
                        key: dataKey,
                        label: typeof label === "string" ? localized(label) : label(),
                        className: `table-cell ${className}`,
                        ...column,
                      }}
                    />
                  )
                })}
              </Table>
            )}
          </AutoSizer>
        </Box>
      )
    },
  ),
)

CustomizedTable.propTypes = {
  list: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }),
  ).isRequired,
  onSort: PropTypes.func,
  sort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(["DESC", "ASC"]),
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  rowClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  noRowsRenderer: PropTypes.func,
}

CustomizedTable.defaultProps = {
  headerHeight: 35,
  rowHeight: 60,
  className: "horizontal-tiles list-group",
  rowClassName: "table-row",
  headerClassName: "no-borders",
  containerClassName: "virtualized-table",
  gridClassName: "",
  onSort: sortTableList,
}

CustomizedTable.displayName = "CustomizedTable"

export default CustomizedTable
