import { createSelector } from "reselect"

const getAllSearches = state => state.groups
const getSearchForEdit = state => state.searchForEdit

export const getSearchSetForEdit = createSelector(
  [getAllSearches, getSearchForEdit],
  (allSearches, searchForEdit) =>
    searchForEdit && (searchForEdit < 0 ? searchForEdit : allSearches.find(s => s.id === searchForEdit) || null),
)
