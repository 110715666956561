import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"
import { sizer } from "@ninjaone/utils"

export const StyledWrapper = styled.div`
  .DayPicker {
    --rdp-cell-size: 32px;
    --rdp-accent-color: ${({ theme }) => theme.colorForegroundFocus};
    --rdp-outline: 2px solid var(--rdp-accent-color);

    margin-top: ${sizer(1)};
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    outline: 0;
  }

  .DayPicker-NavBar {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 3px;
    height: 48px;

    &--singleMonth {
      right: 0;
      width: 51px;
    }

    &--multiMonth {
      width: 100%;

      & button:last-child {
        position: absolute;
        right: 0;
      }
    }
  }

  .DayPicker-Month {
    margin: 0;
  }

  .DayPicker-Caption {
    margin-bottom: ${sizer(1)};
    padding: ${sizer(0, 0, 2)};
    border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  }

  .DayPicker-Caption > div {
    font-weight: ${tokens.typography.fontWeight.semiBold};
    font-size: ${tokens.typography.fontSize.body};
    line-height: 24px;
    color: ${({ theme }) => theme.colorTextStrong};
  }

  .DayPicker-Weekday {
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    font-size: ${tokens.typography.fontSize.body};
    font-weight: ${tokens.typography.fontWeight.medium};
    text-transform: lowercase;
    color: ${({ theme }) => theme.colorTextWeakest};

    &:first-letter {
      text-transform: capitalize;
    }
  }

  .DayPicker-Day {
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 7px 9px;
    font-size: ${tokens.typography.fontSize.body};
    font-weight: ${tokens.typography.fontWeight.medium};
    color: ${({ theme }) => theme.colorTextStrong};
    border-radius: ${tokens.borderRadius[1]};
    cursor: pointer;

    &.DayPicker-Day.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--start):not(.DayPicker-Day--end):hover {
      background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
    }

    &:focus-visible {
      outline: var(--rdp-outline);
    }
  }

  .DayPicker-Day--today {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 3px;
      right: 3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 6px 6px;
      border-color: transparent transparent ${({ theme }) => theme.colorTextStrong} transparent;
    }

    &.DayPicker-Day--outside {
      &:after {
        border-color: transparent transparent ${({ theme }) => theme.colorTextDisabled} transparent;
      }
    }
  }

  .DayPicker-Day--outside,
  .DayPicker-Day--disabled {
    color: ${({ theme }) => theme.colorTextDisabled};
    font-weight: ${tokens.typography.fontWeight.regular};
  }

  .DayPicker-Day--disabled {
    pointer-events: none;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
    font-weight: ${tokens.typography.fontWeight.medium};
    color: ${({ theme }) => theme.colorTextStrongInverse};
    background: ${({ theme }) => theme.colorForegroundSelectedStrongest};

    &:hover {
      background: ${({ theme }) => theme.colorForegroundSelectedStrongest};
    }

    &:after,
    &.DayPicker-Day--outside:after {
      border-color: transparent transparent ${({ theme }) => theme.colorTextStrongInverse} transparent;
    }
  }

  /* Range Styles */
  ${({ mode, theme }) =>
    mode === "range" &&
    `.DayPicker-NavBar--multiMonth + .DayPicker-Months .DayPicker-Caption {
        text-align: center;
      }

      .DayPicker-Months {
        flex-wrap: nowrap;
        gap: ${tokens.spacing[3]};
  
      .DayPicker-Day--outside[aria-disabled] {
        visibility: hidden;
      }

      .DayPicker-Day--start,
      .DayPicker-Day--end,
      .DayPicker-Day--selected {
        font-weight: ${tokens.typography.fontWeight.medium};
        color: ${theme.colorTextStrongInverse};
        background: ${theme.colorForegroundSelectedStrongest};

        &.DayPicker-Day.DayPicker-Day.DayPicker-Day:hover {
          color: ${theme.colorTextStrongInverse};
          background: ${theme.colorForegroundSelectedStrongest};
        }
      }
  
      .DayPicker-Day--start {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
  
      .DayPicker-Day--end {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .DayPicker-Day--start.DayPicker-Day--end {
        border-radius: ${tokens.borderRadius[1]};
      }
  
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
        color: ${theme.colorTextStrong};
        background: ${theme.colorForegroundSelectedStrong};
        border-radius: 0;
      }
    }`}
`
