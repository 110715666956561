import { useMemo } from "react"
import { append, equals, filter, ifElse, includes, propSatisfies, reject, __ } from "ramda"
import MultiSelectTableFilter from "@ninjaone/webapp/src/js/includes/components/MultiSelectTableFilter"

const MultiSelectFilter = ({
  filter: {
    name,
    value: filterValueList = [],
    labelToken,
    componentProps: { options = [], isRemovable = true },
  },
  updateFilter,
  alwaysShowRemove,
  onRemove,
  onDropdownClose,
  openOnMount,
  disabled,
}) => {
  const onSelect = value => {
    const newValue = ifElse(includes(value), reject(equals(value)), append(value))(filterValueList)
    updateFilter(name, newValue)
  }
  const selectedValues = useMemo(() => filter(propSatisfies(includes(__, filterValueList), "value"), options), [
    options,
    filterValueList,
  ])

  return (
    <MultiSelectTableFilter
      {...{
        labelToken,
        options,
        onSelect,
        value: selectedValues,
        isRemovable,
        alwaysShowRemove,
        openOnMount,
        onDropdownClose,
        onRemove: () => {
          onRemove?.(name)
          updateFilter(name, [])
        },
        disabled,
      }}
    />
  )
}

export default MultiSelectFilter
