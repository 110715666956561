/** @jsxImportSource @emotion/react */

import { Modal } from "react-bootstrap"
import withFocusTrap from "@ninjaone/components/src/hoc/withFocusTrap"
import Tooltip from "js/includes/components/Tooltip"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const NinjaModal = function({
  altBtn,
  children,
  close,
  dataTitle,
  loading,
  overflow,
  save,
  saveText,
  saveBtnClass,
  closeText,
  title,
  tooltip,
  submittable,
  dialogClassName,
  bsSize,
  noHeader,
  noFooter,
  tallModal,
  disabledSaveBtn,
  footerLeftComponent,
  showCloseButton,
  cancel,
  disableAltBtn,
  disabledCloseBtn,
  saveBtnCss,
  bodyClassName,
  altBtnClass,
}) {
  return (
    <Modal.Dialog bsSize={bsSize} bsClass="inmodal modal" dialogClassName={dialogClassName}>
      {!noHeader && (
        <Modal.Header>
          <Modal.Title>
            {dataTitle ? <span>{title}</span> : localized(title)}
            {tooltip && <Tooltip placement="bottom" token={tooltip} />}
            {showCloseButton && (
              <button type="button" className="close" onClick={close} disabled={disabledCloseBtn}>
                ×
              </button>
            )}
          </Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body
        bsClass={`modal-body${overflow ? " overflow-visible-important" : ""} ${tallModal ? " tall-modal" : ""}${
          bodyClassName ? ` ${bodyClassName}` : ""
        }`}
      >
        {children}
      </Modal.Body>
      {!noFooter && (
        <Modal.Footer>
          <div className="display-flex justify-content-between">
            <div className="display-flex flex-full align-items-center">{footerLeftComponent}</div>
            <div className="display-flex flex-full justify-content-end">
              {altBtn && (
                <button
                  type="button"
                  className={`btn ${altBtn.btnWhite ? "btn-white" : "btn-success"} ${altBtnClass}`}
                  onClick={altBtn.action}
                  disabled={loading || disableAltBtn}
                >
                  {localized(altBtn.text)}
                </button>
              )}
              {(save || submittable) && (
                <button
                  type={submittable ? "submit" : "button"}
                  className={`btn ${saveBtnClass}`}
                  onClick={save}
                  disabled={loading || disabledSaveBtn}
                  css={saveBtnCss}
                >
                  {localized(saveText ?? localizationKey("Save"))}
                </button>
              )}
              {cancel ? (
                <button type="button" className="btn btn-danger" onClick={cancel}>
                  {localized("Cancel")}
                </button>
              ) : (
                <button type="button" className="btn btn-white" onClick={close} disabled={disabledCloseBtn}>
                  {localized(closeText ?? localizationKey("Close"))}
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      )}
    </Modal.Dialog>
  )
}

NinjaModal.defaultProps = {
  saveBtnClass: "btn-success",
  altBtnClass: "btn-success",
  showCloseButton: true,
}

export default withFocusTrap(NinjaModal)
