import { useMemo } from "react"
import { v4 as uuidv4 } from "uuid"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { Root, Thumb } from "@radix-ui/react-switch"
import tokens from "@ninjaone/tokens"
import { sizer } from "@ninjaone/utils"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"
import { Label } from "./Form/Label"
import Body from "./Typography/Body"

function getSizes(size) {
  switch (size) {
    case "md":
      return { width: "38px", thumb: "16px", transform: "20px", height: "20px" }

    default:
      throw new Error(`Unknown size: ${size}`)
  }
}

const StyledSwitchContainer = styled("div", {
  shouldForwardProp: prop => isPropValid(prop) || prop !== "descriptionText",
})`
  display: flex;
  gap: ${sizer(3)};
  align-items: ${({ descriptionText }) => (descriptionText ? "flex-start" : "center")};
`

const LabelTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledRoot = styled(Root)`
  all: unset;

  min-width: ${({ size }) => getSizes(size).width};
  height: ${({ size }) => getSizes(size).height};

  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutral};
  border-radius: 20px;

  &[data-state="checked"] {
    background-color: ${({ theme }) => theme.colorForegroundSelectedStrongest};
  }

  &[data-disabled] {
    opacity: 0.5;
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    outline-offset: 0;
  }
`

const StyledThumb = styled(Thumb)`
  display: block;

  width: ${({ size }) => getSizes(size).thumb};
  height: ${({ size }) => getSizes(size).thumb};

  background-color: ${({ theme }) => theme.colorBackground};
  border-radius: 100px;

  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;

  &[data-state="checked"] {
    transform: ${({ size }) => `translateX(${getSizes(size).transform})`};
  }
`

function Switch({
  tooltip,
  onChange,
  isLegacy,
  disabled,
  labelText,
  className,
  labelToken,
  size = "md",
  checked = false,
  descriptionText,
  descriptionTextWrap,
}) {
  const label = labelText || localized(labelToken)
  const ariaId = useMemo(() => uuidv4(), [])

  return (
    <StyledSwitchContainer {...{ descriptionText, size, "data-testid": "switch-container" }}>
      <StyledRoot
        {...{
          size,
          checked,
          disabled,
          onCheckedChange: onChange,
          className,
          ...(label && { "aria-labelledby": ariaId }),
          ...(descriptionText && !tooltip && { "aria-describedby": `${ariaId}-description` }),
        }}
      >
        <StyledThumb {...{ size }} />
      </StyledRoot>

      {label && (
        <LabelTextContainer>
          <Label
            {...{
              disabled,
              forInputElement: false,
              id: ariaId,
              labelText: label,
              noMargin: !descriptionText,
              tooltipText: tooltip,
              ...(descriptionText && { fontWeight: tokens.typography.fontWeight.medium }),
            }}
          />

          {descriptionText && !tooltip && (
            <Body
              color="colorTextWeak"
              id={`${ariaId}-description`}
              textWrap={descriptionTextWrap}
              marginTop={`-${tokens.spacing[1]}`}
            >
              {descriptionText}
            </Body>
          )}
        </LabelTextContainer>
      )}
    </StyledSwitchContainer>
  )
}

export default Switch

Switch.propTypes = {
  /**
   * The state of the switch (on/off).
   */
  checked: PropTypes.bool.isRequired,
  /**
   * Callback function invoked when the switch is toggled.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * If true, the switch will be disabled and cannot be toggled.
   */
  disabled: PropTypes.bool,
  /**
   * The token to label the switch.
   * @deprecated Please use `labelText` instead.
   */
  labelToken: PropTypes.string,
  /**
   * The text to label the switch.
   */
  labelText: PropTypes.string,
  /**
   * The description text for the switch.
   */
  descriptionText: PropTypes.string,
  /**
   * Defines whether the description text will wrap to the next line if it overflows the width.
   */
  descriptionTextWrap: PropTypes.bool,
  /**
   * The tooltip text for the switch.
   */
  tooltip: PropTypes.string,
  /**
   * Size variant of the switch.
   */
  size: PropTypes.oneOf(["md"]),
  /**
   * Change the switch colors.
   * @deprecated The `isLegacy` prop is deprecated. We are not supporting old colors anymore.
   */
  isLegacy: PropTypes.bool,
}
