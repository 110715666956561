import styled from "@emotion/styled"
import PropTypes from "prop-types"

import { spacing, typography } from "@ninjaone/tokens"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Checkbox from "./Checkbox"
import HoverTooltip from "./HoverTooltip"

const StyledLegend = styled.legend`
  display: flex;
  align-items: center;
  gap: ${spacing[1]};
  margin-bottom: ${spacing[1]};
  color: ${({ theme }) => theme.colorTextStrong};
  font-weight: ${typography.fontWeight.regular};
  font-size: ${typography.fontSize.body};

  & > button {
    height: 21px;
    width: 14px;
  }
`

const CheckboxGroup = ({ disabled, labelText, options, tooltipText }) => (
  <fieldset style={{ margin: 0, padding: 0, border: 0 }} {...(disabled && { disabled: true, "aria-disabled": true })}>
    <StyledLegend>
      {labelText}
      {tooltipText && (
        <HoverTooltip
          text={tooltipText}
          triggerColor="colorTextWeakest"
          triggerSize="sm"
          triggerAriaLabel={localized("More information")}
        />
      )}
    </StyledLegend>

    {options.map(option => (
      <Checkbox key={option.label} {...{ ...option, disabled }} />
    ))}
  </fieldset>
)

CheckboxGroup.propTypes = {
  /**
   * The disabled state for the group and each checkbox.
   */
  disabled: PropTypes.bool,
  /**
   * The text for the label
   */
  labelText: PropTypes.string.isRequired,
  /**
   * The checkboxes for the group.
   */
  options: PropTypes.array,
  /**
   * The tooltip text for the 'more information' icon.
   */
  tooltipText: PropTypes.string,
}

export default CheckboxGroup
