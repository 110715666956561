import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import Text from "../Text"

const getElementFromLevel = level => {
  switch (level) {
    case 1: {
      return "h1"
    }
    case 2: {
      return "h2"
    }
    case 3: {
      return "h3"
    }
    case 4: {
      return "h4"
    }
    case 5: {
      return "h5"
    }
    case 6: {
      return "h6"
    }
    default: {
      return "h2"
    }
  }
}

const getTypeFromLevel = level => {
  switch (level) {
    case 1: {
      return "headingL"
    }
    case 2: {
      return "headingM"
    }
    case 3: {
      return "headingS"
    }
    case 4:
    case 5:
    case 6: {
      return "headingXs"
    }
    default: {
      return "headingM"
    }
  }
}

const Heading = ({
  children,
  color,
  fontWeight,
  hideTooltip,
  icon,
  iconGap = tokens.spacing[3],
  id,
  level,
  marginBottom,
  marginTop,
  textWrap,
  textWrapLineLimit,
  tooltipPosition,
  type,
  visuallyHidden,
}) => {
  const heading = (
    <Text
      as={getElementFromLevel(level)}
      type={type ?? getTypeFromLevel(level)}
      {...{
        color,
        fontWeight,
        hideTooltip,
        id,
        marginBottom,
        marginTop,
        textWrap,
        textWrapLineLimit,
        tooltipPosition,
        visuallyHidden,
      }}
    >
      {children}
    </Text>
  )

  if (icon) {
    return (
      <Flex alignItems="center" gap={iconGap}>
        {icon}
        {heading}
      </Flex>
    )
  }

  return heading
}

Heading.defaultProps = {
  color: "colorTextStrong",
  fontWeight: tokens.typography.fontWeight.medium,
}

Heading.propTypes = {
  /**
   * Text content for the component.
   */
  children: PropTypes.node.isRequired,
  /**
   * The text color for the component.
   */
  color: PropTypes.string,
  /**
   * Sets the font weight for the component.
   */
  fontWeight: PropTypes.oneOf([400, 500, 600]),
  /**
   * Determines if tooltip should be hidden.
   */
  hideTooltip: PropTypes.bool,
  /**
   * The icon to render before the text.
   */
  icon: PropTypes.element,
  /**
   * The gap between the icon and the text.
   */
  iconGap: PropTypes.string,
  /**
   * The id of the component.
   */
  id: PropTypes.string,
  /**
   * Sets the semantic level of the HTML heading.
   */
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  /**
   * The bottom margin for the component.
   */
  marginBottom: PropTypes.string,
  /**
   * The top margin for the component.
   */
  marginTop: PropTypes.string,
  /**
   * Determines if text should wrap.
   */
  textWrap: PropTypes.bool,
  /**
   * The number of lines text should wrap
   */
  textWrapLineLimit: PropTypes.number,
  /**
   * Position of the tooltip.
   */
  tooltipPosition: PropTypes.func,
  /**
   * Determines the font size and light height for the component.
   */
  type: PropTypes.oneOf(["headingL", "headingM", "headingS", "headingXs"]),
  /**
   * Visually hide the component.
   */
  visuallyHidden: PropTypes.bool,
}

export default Heading
