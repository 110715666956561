import { useRef, useState, Fragment } from "react"
import { cond, anyPass } from "ramda"
import { css } from "@emotion/react"
import {
  isRightKey,
  isLeftKey,
  isUpKey,
  isDownKey,
  isEnterKey,
  isSpaceKey,
} from "@ninjaone/webapp/src/js/includes/common/utils"
import Tooltip from "../Tooltip"
import { UnstyledButton } from "../Styled"

const baseStyle = css`
  &:focus,
  &:focus-visible {
    outline: none;
  }
`

const INITIAL_FOCUSED_INDEX = -1

const useAccessibleActionIcon = ({ actionIcons = [], onIconClick, actionIconStyle, showActionIconOnHover }) => {
  const [focusedIndex, setFocusedIndex] = useState(INITIAL_FOCUSED_INDEX)
  const parentElementRef = useRef(null)
  const actionIconRefs = useRef([])
  const isFocusedOnActionItem = focusedIndex >= 0
  const hasActionIcons = !!actionIcons.length

  const getNextElementAndIndex = currentIndex => {
    const newIndex = currentIndex + 1
    const lastIndex = actionIcons.length - 1
    const nextIndex = newIndex >= lastIndex ? lastIndex : newIndex
    const nextIconElement = actionIconRefs.current[nextIndex]

    return { nextIndex, nextIconElement }
  }

  const getPrevElementAndIndex = currentIndex => {
    const newIndex = currentIndex - 1
    const prevIndex = newIndex < 0 ? INITIAL_FOCUSED_INDEX : newIndex
    const prevIconElement =
      prevIndex === INITIAL_FOCUSED_INDEX ? parentElementRef.current : actionIconRefs.current[newIndex]

    return { prevIndex, prevIconElement }
  }

  const parentElementOnKeyDown = event => {
    cond([
      [
        isRightKey,
        () => {
          const { nextIndex, nextIconElement } = getNextElementAndIndex(focusedIndex)

          if (nextIconElement) {
            nextIconElement.focus()
            setFocusedIndex(nextIndex)
          }

          event.stopPropagation()
        },
      ],
      [
        isLeftKey,
        () => {
          const { prevIndex, prevIconElement } = getPrevElementAndIndex(focusedIndex)

          if (prevIconElement) {
            prevIconElement.focus()
            setFocusedIndex(prevIndex)
          }

          event.stopPropagation()
        },
      ],
      [
        anyPass([isUpKey, isDownKey]),
        () => {
          if (isFocusedOnActionItem) {
            event.stopPropagation()
          }
        },
      ],
    ])(event)
  }

  return hasActionIcons
    ? {
        parentElementProps: {
          ref: parentElementRef,
          onKeyDown: parentElementOnKeyDown,
          onBlur: () => {
            setFocusedIndex(INITIAL_FOCUSED_INDEX)
          },
        },
        actionIconsRenderer: () =>
          actionIcons.map(({ element, tooltip, onClick, ref, sideOffset, id }, index) => {
            if (!element) return null

            const actionIconWrapper = (
              <UnstyledButton
                {...{
                  ref: element => {
                    actionIconRefs.current[index] = element
                  },
                  onClick: event => {
                    event.stopPropagation()
                    event.preventDefault()

                    onClick(event)
                    onIconClick?.()
                  },
                  onKeyDown: event => {
                    if (isEnterKey(event) || isSpaceKey(event)) {
                      event.preventDefault()

                      onClick(event)
                      onIconClick?.()
                    }
                  },
                  css: [baseStyle, actionIconStyle],
                  tabIndex: INITIAL_FOCUSED_INDEX,
                  "aria-label": tooltip,
                  id,
                }}
              >
                {element}
              </UnstyledButton>
            )

            return (
              <Fragment key={tooltip || index}>
                {tooltip ? (
                  <Tooltip align="center" label={tooltip} position="right" sideOffset={sideOffset}>
                    {actionIconWrapper}
                  </Tooltip>
                ) : (
                  actionIconWrapper
                )}
              </Fragment>
            )
          }),
      }
    : {}
}

export default useAccessibleActionIcon
