import { always, ifElse, is } from "ramda"
import SearchableSelectTableFilter from "@ninjaone/webapp/src/js/includes/components/SearchableSelectTableFilter"

const SearchableSelectFilter = ({
  filter: {
    name,
    value: filterValueList = [],
    labelToken,
    defaultValue,
    componentProps: {
      options,
      isMulti,
      isRemovable = !defaultValue,
      valueSelectorKey = "value",
      searchableKey,
      ...restProps
    },
  },
  updateFilter,
  alwaysShowRemove,
  openOnMount,
  onRemove,
  onDropdownClose,
  disabled,
}) => {
  const onSelect = value => {
    updateFilter(name, ifElse(is(Array), always(value), always([value]))(value))
  }

  return (
    <SearchableSelectTableFilter
      {...{
        labelToken,
        options,
        value: filterValueList,
        onSelect,
        isRemovable,
        alwaysShowRemove,
        openOnMount,
        onDropdownClose,
        onRemove: () => {
          onRemove?.(name)
          updateFilter(name, [])
        },
        isMulti,
        searchableProps: {
          searchableKey,
          valueSelectorKey,
          ...restProps,
        },
        disabled,
        labelSelectorKey: searchableKey,
      }}
    />
  )
}

export default SearchableSelectFilter
