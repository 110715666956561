import { Trigger } from "@radix-ui/react-tabs"
import styled from "@emotion/styled/macro"
import isPropValid from "@emotion/is-prop-valid"

import { InputErrorIcon, OpenArrowIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Text from "../../Text"

const StyledTab = styled(Trigger, {
  shouldForwardProp: prop => isPropValid(prop) || prop !== "displayError",
})`
  height: 38px;
  min-height: 0px;

  border-bottom: 1px solid transparent;
  padding: ${sizer(0, 3)};

  align-items: center;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;

  grid-column-gap: ${sizer(1)};

  user-select: none;
  cursor: pointer;
  outline: none;

  border: none;
  border-bottom: 1px solid transparent;

  background-color: transparent !important;

  // Tooltip color
  color: ${({ displayError, theme }) => (displayError ? theme.colorAlertError : theme.colorTextWeakest)};

  &[data-disabled] {
    cursor: not-allowed;

    &:hover {
      background-color: transparent !important;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover} !important;
  }

  &[data-state="active"] {
    border-bottom-color: ${({ theme }) => theme.colorTextStrong} !important;
    z-index: 1;
    position: relative;

    > * {
      font-weight: ${tokens.typography.fontWeight.medium};
      color: ${({ displayError, theme }) => (displayError ? theme.colorAlertError : theme.colorTextStrong)};
    }
  }
`

const StyledOpenArrowIcon = styled(OpenArrowIcon)`
  margin-left: ${tokens.spacing[2]};
`

const TabTriggerContent = ({ labelToken, labelText, tabIndex, displayError, count, hasOptions, disabled }) => {
  return (
    <StyledTab {...{ value: tabIndex, displayError, disabled }}>
      <Text
        {...{
          type: "headingXs",
          token: labelToken,
          children: labelText,
          color: displayError ? "colorAlertError" : "colorTextWeakest",
        }}
      />

      {displayError && <InputErrorIcon {...{ label: localized("Error") }} />}
      {hasOptions && <StyledOpenArrowIcon {...{ color: "colorTextWeakest", size: "xs", direction: "down" }} />}
      {count !== undefined && <Text {...{ type: "headingXs", color: "colorTextWeakest" }}>({count})</Text>}
    </StyledTab>
  )
}

export default TabTriggerContent
