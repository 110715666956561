import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"

import { Label } from "../../Form/Label"

export const StyledLabelContainer = styled(Box)`
  margin-bottom: ${tokens.spacing[1]};
  color: ${({ theme }) => theme.colorTextStrong};
`

export function SelectLabelComponent({
  ariaId,
  disabled,
  labelText,
  labelToken,
  required,
  titleRenderer,
  tooltipText,
}) {
  return (
    <StyledLabelContainer>
      {titleRenderer?.() ?? (
        <Label
          id={ariaId}
          forInputElement={false}
          labelText={labelText ? labelText : localized(labelToken)}
          {...{ disabled, required, tooltipText }}
        />
      )}
    </StyledLabelContainer>
  )
}
