import styled from "@emotion/styled"
import { SelectArrow, useSelectContext } from "@ariakit/react"

import tokens from "@ninjaone/tokens"
import { ChevronDownLightIcon, ChevronUpLightIcon, CloseIconLight } from "@ninjaone/icons"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import { ErrorIconTooltip } from "../../IconTooltips"

const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing[1]};

  position: absolute;
  right: 7px;
  top: 6px;
`

const StyledIconContainer = styled.div`
  height: 24px;
  width: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${tokens.borderRadius[1]};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledErrorIconTooltipContainer = styled(StyledIconContainer)`
  & > button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 14px;
    height: 14px;

    border: 0;
    border-radius: ${tokens.borderRadius[2]};

    background: none;

    &:focus-visible {
      outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    }
  }
`

const StyledClearButton = styled(StyledIconContainer)`
  background: none;
  border: none;

  cursor: pointer;
`.withComponent("button")

const StyledDivider = styled.div`
  height: 24px;
  width: 1px;

  border: none;
  background-color: ${({ theme }) => theme.colorBorderWeakest};
`

export function SelectActions({ ariaId, errorMessage, disabled, isMulti, hideClearSelectionButton }) {
  const selectStore = useSelectContext()

  const isSelectOpen = selectStore.useState("open")
  const value = selectStore.useState("value")

  const hasSelectedValue = isMulti ? value.length > 0 : !!value

  const handleOnClick = event => {
    selectStore.setValue(isMulti ? [] : "")
    selectStore.hide()

    event.preventDefault()
  }

  const showClearButton = hasSelectedValue && !disabled && !errorMessage && !hideClearSelectionButton

  return (
    <StyledActionsContainer>
      {errorMessage && !disabled && (
        <StyledErrorIconTooltipContainer>
          <ErrorIconTooltip
            {...{
              ariaError: `${localized("Error")} ${errorMessage}`,
              ariaId,
              ariaLive: "assertive",
              error: errorMessage,
              tooltipAlignment: "end",
              triggerAsChild: false,
            }}
          />
        </StyledErrorIconTooltipContainer>
      )}

      {showClearButton && (
        <StyledClearButton {...{ type: "button", onClick: handleOnClick, "aria-label": localized("Clear selection") }}>
          <CloseIconLight {...{ color: "colorTextWeakest" }} />
        </StyledClearButton>
      )}

      {(showClearButton || errorMessage) && <StyledDivider {...{ "aria-hidden": true }} />}

      <StyledIconContainer>
        <SelectArrow
          {...{ render: isSelectOpen ? ChevronUpLightIcon : ChevronDownLightIcon, color: "colorTextWeakest" }}
        />
      </StyledIconContainer>
    </StyledActionsContainer>
  )
}
