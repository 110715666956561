import { useState, useEffect } from "react"
import DayPicker, { DateUtils } from "react-day-picker"
import MomentLocaleUtils from "react-day-picker/moment"
import PropTypes from "prop-types"

import { StyledWrapper } from "./DatePickerWrapper"
import { Navbar } from "./DatePickerNavbar"

const DatePickerCalendar = ({
  captionElement,
  disabledDays,
  fromMonth,
  initialMonth,
  locale,
  mode,
  month,
  onBlur,
  onDayChange,
  onDayMouseEnter,
  onFocus,
  selectedDays,
  toMonth,
  useDefaultWrapperStyles,
  yearMinOffset,
  yearMaxOffset,
}) => {
  const [selected, setSelected] = useState(selectedDays)
  const isSingleMode = mode === "single"
  const currentYear = new Date().getFullYear()
  const rangeModifiers =
    !isSingleMode && selected
      ? {
          start: selected.from,
          end: selected.to,
        }
      : {}

  const handleDaySelect = (date, modifiers = {}) => {
    if (modifiers.disabled || modifiers.outside) {
      return
    }

    if (isSingleMode) {
      onDayChange?.({ date, modifiers })
      setSelected(modifiers.selected ? null : date)
    } else {
      const newRange = selected ? DateUtils.addDayToRange(date, selected) : { from: date, to: null }
 
      onDayChange?.({ date, range: newRange, modifiers })
      setSelected(newRange)
    }
  }

  useEffect(() => {
    setSelected(selectedDays)
  }, [selectedDays])

  return (
    <StyledWrapper {...{ mode, useDefaultWrapperStyles }}>
      <DayPicker
        disabledDays={[
          ...(Array.isArray(disabledDays) ? disabledDays : [disabledDays]),
          {
            before: yearMinOffset ? new Date(currentYear - yearMinOffset, 0, 1) : new Date(1970, 0, 1),
            after: yearMaxOffset && new Date(currentYear + yearMaxOffset, 11, 31),
          },
        ]}
        fixedWeeks={isSingleMode}
        initialMonth={initialMonth ? initialMonth : isSingleMode ? selected : selected?.from}
        localeUtils={MomentLocaleUtils}
        modifiers={{
          ...rangeModifiers,
        }}
        navbarElement={
          <Navbar
            {...{
              captionElement,
              disabledDays,
              fromMonth,
              toMonth,
              numberOfMonths: isSingleMode ? 1 : 2,
              yearMinOffset,
              yearMaxOffset,
            }}
          />
        }
        numberOfMonths={isSingleMode ? 1 : 2}
        onDayClick={handleDaySelect}
        selectedDays={selected}
        showOutsideDays={isSingleMode}
        {...{
          captionElement,
          fromMonth,
          locale,
          month,
          onBlur,
          onDayMouseEnter,
          onFocus,
          toMonth,
        }}
      />
    </StyledWrapper>
  )
}

DatePickerCalendar.propTypes = {
  /**
   * Custom element to replace the default display of the month and year.
   */
  captionElement: PropTypes.func,
  /**
   * Day(s) that should appear as disabled. Set a disabled modifier.
   * See [Matching days](https://react-day-picker-v7.netlify.app/docs/matching-days)
   * for a reference of the accepted value types.
   */
  disabledDays: PropTypes.any,
  /**
   * The first allowed month. Users won’t be able to navigate or interact with
   * the days before it. See also `toMonth`.
   */
  fromMonth: PropTypes.instanceOf(Date),
  /**
   * The month to display in the calendar at first render. This differs from
   * the `month` prop, as it won’t re-render the calendar if its value changes.
   */
  initialMonth: PropTypes.instanceOf(Date),
  /**
   * The calendar’s locale. Defaults to browser's locale.
   */
  locale: PropTypes.string,
  /**
   * Determines if the calendar should allow selection of a single day or a
   * range of days. Single will display one month while range will display
   * two months.
   */
  mode: PropTypes.oneOf(["single", "range"]),
  /**
   * The month to display in the calendar. This differs from the `initialMonth`
   * prop, as it causes the calendar to re-render when its value changes.
   */
  month: PropTypes.instanceOf(Date),
  /**
   * Event handler when the calendar get the blur event.
   */
  onBlur: PropTypes.func,
  /**
   * Event handler when a day is selected.
   */
  onDayChange: PropTypes.func,
  /**
   * Event handler when the mouse enters a day cell.
   */
  onDayMouseEnter: PropTypes.func,
  /**
   * Event handler when the calendar get the focus event.
   */
  onFocus: PropTypes.func,
  /**
   * Day(s) that should appear as selected. Set a `selected` modifier.
   * See [Matching days](https://react-day-picker-v7.netlify.app/docs/matching-days)
   * for a reference of the accepted value types.
   */
  selectedDays: PropTypes.any,
  /**
   * The last allowed month. Users won’t be able to navigate or interact with
   * the days after it. See also `fromMonth`.
   */
  toMonth: PropTypes.instanceOf(Date),
  /**
   * Determines if default styles for a popover should be used.
   */
  useDefaultWrapperStyles: PropTypes.bool,
}

DatePickerCalendar.defaultProps = {
  locale: navigator.language,
  mode: "single",
}

export default DatePickerCalendar
