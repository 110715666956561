import { Provider, Root, Trigger, Portal, Content } from "@radix-ui/react-tooltip"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import PropTypes from "prop-types"

import { EyeOpenIcon, EyeSlashIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"

const StyledTrigger = styled(Trigger, {
  shouldForwardProp: isPropValid,
})`
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: ${tokens.borderRadius[1]};
  background-color: transparent;
  cursor: pointer;
  ${({ positioningStyles }) => positioningStyles}

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  &[aria-disabled="true"] {
    color: ${({ theme }) => theme.colorTextDisabled};
    background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
    cursor: not-allowed;
  }
`

const StyledContent = styled(Content)`
  z-index: 9999;
  padding: ${tokens.spacing[1]} ${tokens.spacing[2]};
  border-radius: ${tokens.borderRadius[1]};
  font-size: ${tokens.typography.fontSize.bodyXs};
  color: ${({ theme }) => theme.colorTextHighContrast};
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralDarker};
`

const VisibilityToggle = ({
  alignTooltip = "end",
  disabled,
  isVisible,
  isNotVisibleLabel,
  isVisibleLabel,
  positioningStyles,
  positionTooltip = "bottom",
  onClick,
}) => {
  const label = isVisible ? isVisibleLabel : isNotVisibleLabel

  const handleClick = e => {
    e.preventDefault() // necessary to persist the tooltip's open state on click
    onClick()
  }

  return (
    <Provider>
      <Root>
        <StyledTrigger
          onClick={handleClick}
          tabIndex={0}
          aria-label={label}
          aria-live="polite"
          aria-disabled={disabled}
          data-ninja-password-toggle=""
          {...{ disabled, positioningStyles }}
        >
          {isVisible ? <EyeSlashIcon color="colorTextStrong" /> : <EyeOpenIcon color="colorTextStrong" />}
        </StyledTrigger>

        {!disabled && (
          <Portal>
            <StyledContent
              align={alignTooltip}
              side={positionTooltip}
              sideOffset={4}
              onPointerDownOutside={e => e.preventDefault()} // necessary to persist the tooltip's open state on trigger click
            >
              {label}
            </StyledContent>
          </Portal>
        )}
      </Root>
    </Provider>
  )
}

VisibilityToggle.propTypes = {
  /**
   * The horizontal alignment of the tooltip.
   */
  alignTooltip: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * Disables the trigger and sets disabled styles.
   */
  disabled: PropTypes.bool,
  /**
   * The label renderered when the component is not visible.
   */
  isNotVisibleLabel: PropTypes.string.isRequired,
  /**
   * Determines the triggr icon and tooltip text.
   */
  isVisible: PropTypes.bool.isRequired,
  /**
   * The label rendered when the component is visible.
   */
  isVisibleLabel: PropTypes.string.isRequired,
  /**
   * The callback run when the trigger is clicked.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Additional styles used for positioning tooltip trigger.
   */
  positioningStyles: PropTypes.string,
  /**
   * The position of the tooltip relative to the copy button.
   */
  positionTooltip: PropTypes.oneOf(["top", "right", "bottom", "left"]),
}

export default VisibilityToggle
