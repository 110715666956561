import React, { memo, useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { map } from "ramda"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tokens from "@ninjaone/tokens"
import { layout, color, border, flexbox, space } from "js/includes/components/Styled/system"
import { useMounted } from "js/includes/common/hooks"
import { isBackspaceKey, isEnterKey } from "js/includes/common/utils"

const StyledContainer = styled.div`
  min-height: ${({ minHeight }) => minHeight};
  max-height: 300px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  overflow: hidden auto;
  position: relative;
  padding: ${tokens.spacing[1]} ${tokens.spacing[2]};
`

const StyledInput = styled.input`
  margin: 0px ${tokens.spacing[1]} 0px 0px;
  width: ${({ value }) => `${(value.length + 2) * 8}px`};
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  &:focus {
    outline: none;
  }
`

const StyledTagContainer = styled.span`
  min-height: 18px;
  max-height: 36px;
  padding-left: ${tokens.spacing[2]};
  display: flex;
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};
  color: ${({ theme }) => theme.colorTextStrong};
  box-sizing: border-box;
  border-radius: 2px;
  font-size: ${tokens.typography.fontSize.bodyXs};
  margin: ${tokens.spacing[1]} ${tokens.spacing[1]} ${tokens.spacing[1]} 0px;
  position: relative;
  align-items: center;
  max-width: 100%;
  ${layout}
  ${color}
  ${border}
  ${flexbox}
  ${space}
`

const StyledDeleteButton = styled.span`
  font-size: ${tokens.typography.fontSize.bodyXs};
  padding: 0px ${tokens.spacing[2]};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  border-radius: 2px;

  &:hover {
    cursor: pointer;
  }
`

const StyledOptionName = styled.span`
  line-height: 1.2;
  margin: 2px 0px;
`

const StyledPlaceholder = styled.span`
  color: ${({ theme }) => theme.colorTextWeakest};
  position: absolute;
  margin-left: 2px;
`

const MultiSelectInput = memo(
  ({
    searchOnly,
    minHeight,
    autoFocus,
    focus,
    valueSelectorKey,
    searchableKey,
    selected,
    setSelected,
    value,
    id,
    onChange,
    onDelete,
    setFocus,
    placeholder,
    tagRenderer,
    isCreatable,
    handleCreateNew,
    openOnFocus,
    setOpen,
    disabled,
    pressedKeyCreatesNewOption = isEnterKey,
    pressedKeyDeleteOption = isBackspaceKey,
    handleDeleteLastSelection,
    useFilterStyling,
    maxLength,
    ariaAttributes,
  }) => {
    const mounted = useMounted()
    const inputRef = useRef(null)

    useEffect(() => {
      let timeout
      if (focus && mounted?.current && inputRef?.current) {
        if (useFilterStyling) {
          inputRef.current.focus()
        } else {
          timeout = setTimeout(() => inputRef.current?.focus(), 500)
        }
      }
      return () => clearTimeout(timeout)
    }, [focus, mounted, useFilterStyling])

    useEffect(() => {
      const onKeyDownSelect = e => {
        if (pressedKeyCreatesNewOption(e) && isCreatable && focus) {
          handleCreateNew()
        } else if (!searchOnly && pressedKeyDeleteOption(e) && focus && !value.length) {
          handleDeleteLastSelection()
        }
      }

      document.addEventListener("keydown", onKeyDownSelect)
      return () => {
        document.removeEventListener("keydown", onKeyDownSelect)
      }
    }, [
      focus,
      searchOnly,
      isCreatable,
      handleCreateNew,
      pressedKeyCreatesNewOption,
      handleDeleteLastSelection,
      pressedKeyDeleteOption,
      value,
    ])

    return (
      <StyledContainer
        minHeight={minHeight}
        onClick={() => {
          if (disabled) return
          openOnFocus && setOpen(true)
          setFocus(true)
        }}
      >
        {!value?.length && (!selected?.length || searchOnly) && <StyledPlaceholder>{placeholder}</StyledPlaceholder>}

        {!searchOnly &&
          map(option => {
            const tagLabelValue =
              typeof searchableKey === "function" ? searchableKey(option) : option[searchableKey || valueSelectorKey]

            const deleteTagAction = () => onDelete(option)

            const renderTagOptionName = value => (
              <StyledOptionName className="text-ellipsis">{value || tagLabelValue}</StyledOptionName>
            )

            const renderTagDeleteAction = () => (
              <StyledDeleteButton onClick={deleteTagAction} data-testid="delete-tag-action">
                <FontAwesomeIcon icon={faTimes} />
              </StyledDeleteButton>
            )

            return tagRenderer ? (
              tagRenderer({
                option,
                renderTagOptionName,
                renderTagDeleteAction,
                deleteTagAction,
                tagLabelValue,
                TagWrapper: StyledTagContainer,
              })
            ) : (
              <StyledTagContainer
                key={option[valueSelectorKey]}
                paddingRight={disabled ? 1 : 0}
                data-testid="input-tag"
              >
                {renderTagOptionName()}
                {!disabled && renderTagDeleteAction()}
              </StyledTagContainer>
            )
          }, selected)}

        <span>
          <StyledInput
            ref={inputRef}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onKeyDown={e => {
              isEnterKey(e) && e.preventDefault()
            }}
            {...{ value, onChange, autoFocus, disabled, id }}
            {...(maxLength && { maxLength })}
            {...ariaAttributes}
          />
        </span>
      </StyledContainer>
    )
  },
)

MultiSelectInput.defaultProps = {
  value: [],
  ariaAttributes: {},
}

export default MultiSelectInput
