import { compose, filter, take, is, map, toLower, any, cond, includes as ramdaIncludes, T, toString } from "ramda"
import { transduce } from "js/includes/common/utils"

export const getFieldValue = (obj, field) => {
  const fieldReference = obj[field]
  if (typeof field === "function") {
    return field(obj)
  } else if (typeof obj.get === "function") {
    return obj.get(field)
  } else {
    return fieldReference
  }
}

export const includes = (obj, field, value) => {
  const fieldValue = getFieldValue(obj, field) || ""
  return cond([
    [is(Array), compose(any(ramdaIncludes(value)), map(toLower))],
    [is(String), compose(ramdaIncludes(value), toLower)],
    [is(Number), compose(ramdaIncludes(value), toString)],
    [
      T,
      () => {
        throw new Error("type is not supported")
      },
    ],
  ])(fieldValue)
}

export function filterData(str = "", data = [], keys = [], limit = 10) {
  const lowercasedValue = str.toLowerCase()
  return str
    ? transduce(
        compose(
          filter(d => keys.some(key => includes(d, key, lowercasedValue))),
          take(limit),
        ),
        data,
      )
    : []
}
