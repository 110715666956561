import styled from "@emotion/styled"
import { Value } from "@radix-ui/react-select"

import Text from "../../Text"

import { arrowDownIconSize } from "../utils/constants"
import { localizationKey, localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

export const StyledValueContainer = styled.div`
  max-width: calc(100% - ${arrowDownIconSize}px);
  text-align: start;
`

export function SelectValueComponent({ labelRenderer, placeholderLabel, placeholderToken, isOpen, children }) {
  if (labelRenderer) {
    return labelRenderer()
  }

  return (
    <StyledValueContainer>
      <Value
        placeholder={
          <Text forceRefreshOnLoad type="body" color="colorTextWeakest">
            {typeof placeholderLabel === "function"
              ? placeholderLabel({ isExpanded: isOpen })
              : placeholderLabel || localized(placeholderToken ?? localizationKey("Select"))}
          </Text>
        }
      >
        {children}
      </Value>
    </StyledValueContainer>
  )
}
