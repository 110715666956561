import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { CustomCheckboxContainer, CustomCheckboxInput } from "@reach/checkbox"
import { sizer } from "@ninjaone/utils"
import "@reach/checkbox/styles.css"
import { StyledSpan } from "js/includes/components/Styled"

const StyledCheckboxContainer = styled(CustomCheckboxContainer)`
  position: relative;
  width: ${sizer(5)};
  height: ${sizer(5)};
  background: ${({ checked, disabled, theme }) =>
    disabled
      ? theme.color.lightGray
      : checked
      ? theme.color.action
      : `linear-gradient(180deg, ${theme.color.background} 0%, ${theme.color.veryLightGray} 100%)`};
  border: ${({ error, theme, checked, disabled }) =>
    `1px solid ${
      error ? theme.color.ninjaRed : checked ? theme.color.action : disabled ? theme.color.lightGray : theme.color.gray
    }`};
  border-radius: 3px;

  // Overrides for reach styling
  box-shadow: none !important;
  outline: 0 !important;
  margin: 0 !important;
`
const StyledCheckbox = styled.div`
  svg {
    fill: none;
    stroke: ${({ theme }) => theme.color.background};
    stroke-width: 3px;
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }
`

const StyledLabelContainer = styled.label`
  display: flex;
`
/**
 * @deprecated This component is no longer recommended for use.
 * */
export default function Checkbox(props) {
  const { checked, onChange, error, disabled, label, fontWeight } = props

  const _Checkbox = (
    <StyledCheckboxContainer {...{ checked, onChange, disabled }} onClick={e => e.stopPropagation()}>
      <CustomCheckboxInput {...props} />
      <StyledCheckbox aria-hidden {...{ checked, error }}>
        <svg viewBox="0 0 22 22">
          <polyline points="17 5 10 15 5 11" />
        </svg>
      </StyledCheckbox>
    </StyledCheckboxContainer>
  )

  return label ? (
    <StyledLabelContainer>
      {_Checkbox}
      <StyledSpan paddingLeft={2} fontWeight={fontWeight}>
        {label}
      </StyledSpan>
    </StyledLabelContainer>
  ) : (
    _Checkbox
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}
