import styled from "@emotion/styled"
import PropTypes from "prop-types"

import { SpinnerIconSolid } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Body from "./Typography/Body"

const StyledContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${tokens.spacing[2]};
`

export default function Spinner({ labelText = localized("Loading...") }) {
  return (
    <StyledContainer>
      <SpinnerIconSolid color="colorTextWeak" spin />
      <Body color="colorTextWeak">{labelText}</Body>
    </StyledContainer>
  )
}

Spinner.propTypes = {
  /**
   * Text for the spinner. Always include a clear message and be mindful of space and **localization**.
   */
  labelText: PropTypes.string,
}
