import { useState } from "react"
import PropTypes from "prop-types"
import { always, cond, isNil, T } from "ramda"

import { TableFilterButton } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"

import { isNumber } from "js/includes/common/utils"
import NumberRange from "js/includes/components/NumberRange"
import OutsideClickAlerter from "js/includes/components/OutsideClickAlerter"
import { Box, StyledSpan } from "js/includes/components/Styled"

const NumberRangeTableFilter = ({
  min,
  max,
  onSelect,
  value,
  labelToken,
  isRemovable,
  alwaysShowRemove,
  onRemove,
  openOnMount = false,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(openOnMount)

  const formatFilters = cond([
    [isNil, always([])],
    [T, ({ from, to }) => (isNumber(from) ? [{ label: `${from}${isNumber(to) ? `-${to}` : ""}` }] : [])],
  ])

  return (
    <Box width="fit-content">
      <OutsideClickAlerter handleClickOutside={() => setIsOpen(false)}>
        <Box position="relative">
          <TableFilterButton
            {...{
              hideTooltip: isOpen,
              handleClick: () => setIsOpen(isOpen => !isOpen),
              labelToken,
              filters: formatFilters(value),
              isRemovable,
              alwaysShowRemove,
              onRemove: () => {
                onRemove?.()
                isOpen && setIsOpen(false)
              },
              disabled,
            }}
          />
          <Box position="absolute" top={`calc(100% + ${sizer(1)})`} zIndex={1000}>
            {isOpen && (
              <StyledSpan
                backgroundColor="white"
                padding={1}
                display="inline-block"
                width={360}
                border="1px solid"
                borderColor="ninjaLight"
                borderRadius={4}
              >
                <NumberRange
                  min={min}
                  max={max}
                  from={value?.from}
                  to={value?.to}
                  onChange={values => {
                    onSelect(values)
                    setIsOpen(false)
                  }}
                />
              </StyledSpan>
            )}
          </Box>
        </Box>
      </OutsideClickAlerter>
    </Box>
  )
}

NumberRangeTableFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
  }),
  labelToken: PropTypes.string.isRequired,
  isRemovable: PropTypes.bool,
  alwaysShowRemove: PropTypes.bool,
  onRemove: PropTypes.func,
}

export default NumberRangeTableFilter
