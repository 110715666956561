import { useRef } from "react"
import { getMouseSpeed, getSlopeBetweenTwoPoints } from "@ninjaone/utils"

const HIT_AREA_Z_INDEX = 10
const HIT_AREA_BUTTON_Z_INDEX = HIT_AREA_Z_INDEX + 1

const getPopoverIsInFinalPosition = popoverBoundaries => popoverBoundaries.y >= 0 && popoverBoundaries.x >= 0

export const useDynamicHitArea = ({ isOpen, buttonWrapperRef }) => {
  const hitAreaRef = useRef()
  const prevMouseEventRef = useRef()
  const popoverBoundariesRef = useRef()
  const isLeftPlacementRef = useRef()

  const hideHitArea = () => {
    if (hitAreaRef.current) {
      hitAreaRef.current.style.display = "none"
    }
  }

  const resetHitArea = () => {
    if (hitAreaRef.current) {
      hitAreaRef.current.style.display = ""
    }
  }

  const getMouseDirectionIsWithinRange = currentMouseEvent => {
    const popoverBoundaries = popoverBoundariesRef.current
    const prevMouseEvent = prevMouseEventRef.current
    const isLeftPlacement = isLeftPlacementRef.current

    const slopeOfPrevMousePointToCurrentPoint = getSlopeBetweenTwoPoints(
      prevMouseEvent.pageX,
      prevMouseEvent.pageY * -1,
      currentMouseEvent.nativeEvent.pageX,
      currentMouseEvent.nativeEvent.pageY * -1,
    )

    const slopeOfPrevMousePointToDropdownTopCorner = getSlopeBetweenTwoPoints(
      prevMouseEvent.pageX,
      prevMouseEvent.pageY * -1,
      isLeftPlacement ? popoverBoundaries.x + popoverBoundaries.width : popoverBoundaries.x,
      popoverBoundaries.top * -1,
    )

    const slopeOfPrevMousePointToDropdownBottomCorner = getSlopeBetweenTwoPoints(
      prevMouseEvent.pageX,
      prevMouseEvent.pageY * -1,
      isLeftPlacement ? popoverBoundaries.x + popoverBoundaries.width : popoverBoundaries.x,
      popoverBoundaries.bottom * -1,
    )

    const isLeftPlacementSlopeWithinRange =
      slopeOfPrevMousePointToCurrentPoint <= slopeOfPrevMousePointToDropdownBottomCorner &&
      slopeOfPrevMousePointToCurrentPoint >= slopeOfPrevMousePointToDropdownTopCorner

    const isLeftPlacementDirectionWithinRange =
      isLeftPlacementSlopeWithinRange && currentMouseEvent.pageX < prevMouseEvent.pageX

    const isRightPlacementSlopeWithinRange =
      slopeOfPrevMousePointToCurrentPoint >= slopeOfPrevMousePointToDropdownBottomCorner &&
      slopeOfPrevMousePointToCurrentPoint <= slopeOfPrevMousePointToDropdownTopCorner

    const isRightPlacementDirectionWithinRange =
      isRightPlacementSlopeWithinRange && currentMouseEvent.pageX > prevMouseEvent.pageX

    return isLeftPlacement ? isLeftPlacementDirectionWithinRange : isRightPlacementDirectionWithinRange
  }

  const detectMouseSpeedAndDirectionAndHideHitArea = event => {
    if (!popoverBoundariesRef.current || !prevMouseEventRef.current) {
      hideHitArea()
      return
    }

    const hitAreaHideDelay = 250
    const popoverBoundaries = popoverBoundariesRef.current
    const mouseSpeed = getMouseSpeed(event, prevMouseEventRef.current)
    const popoverIsInFinalPosition = getPopoverIsInFinalPosition(popoverBoundaries)
    const speedIsWithinRange = mouseSpeed > 100

    const directionIsWithinRange = getMouseDirectionIsWithinRange(event)
    const delayHidingOfHitArea = speedIsWithinRange && directionIsWithinRange && popoverIsInFinalPosition

    if (delayHidingOfHitArea) {
      setTimeout(() => {
        hideHitArea()
      }, hitAreaHideDelay)
    } else {
      hideHitArea()
    }
  }

  const getHitAreaTop = ({ popoverBoundaries }) => {
    const popoverTop = popoverBoundaries.top
    const popoverIsInFinalPosition = getPopoverIsInFinalPosition(popoverBoundaries)

    if (popoverIsInFinalPosition) {
      return popoverTop
    }
  }

  const trackMouseEvent = event => (prevMouseEventRef.current = event)

  const hitAreaRenderer = ({ popoverBoundaries, isLeftPlacement }) => {
    popoverBoundariesRef.current = popoverBoundaries
    isLeftPlacementRef.current = isLeftPlacement

    return (
      <div
        ref={hitAreaRef}
        style={{
          position: "fixed",
          width: buttonWrapperRef.current.clientWidth,
          height: popoverBoundaries.height,
          top: getHitAreaTop({ popoverBoundaries }),
          zIndex: HIT_AREA_Z_INDEX,
        }}
      ></div>
    )
  }

  const hitAreaButtonStyle = isOpen ? { position: "relative", zIndex: HIT_AREA_BUTTON_Z_INDEX } : {}

  return {
    hitAreaRenderer,
    hitAreaButtonStyle,
    hitAreaButtonMouseEvents: {
      onMouseEnter: event => {
        resetHitArea()
        trackMouseEvent(event)
      },
      onMouseLeave: detectMouseSpeedAndDirectionAndHideHitArea,
      onMouseMove: trackMouseEvent,
    },
  }
}
